import React, { useEffect, useState, createContext } from 'react';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Loading from '../components/Loading/Loading';

export interface WorkOrderType {
  counts: number;
  normalized_counts: number;
  workorder_service_type_name: string;
}

interface ContractorAssigned {
  contractor_full_name: string;
  counts: number;
  normalized_counts: number;
}

interface TechnicalHealthEntry {
  contractors_assigned: ContractorAssigned[];
  technical_health: number;
  wo_count: number;
  wo_types: WorkOrderType[];
}

type TechnicalHealthData = {
  daily: {
    [day: string]: TechnicalHealthEntry;
  };
  units: number;
  unitsWithIssues: number;
};

const initialValue: TechnicalHealthData = {
  daily: {},
  units: 0,
  unitsWithIssues: 0,
};

export const TechnicalHealthContext =
  createContext<TechnicalHealthData>(initialValue);

type TechnicalHealthProviderProps = {
  children: React.ReactNode;
};

const TechnicalHealthProvider: React.FC<TechnicalHealthProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<TechnicalHealthData>(initialValue);
  const [isLoading3m, setIsLoading3m] = useState(true);
  const [isLoadingCollection, setIsLoadingCollection] = useState(true);

  const isLoading = isLoadingCollection || isLoading3m;

  useEffect(() => {
    const docRef = doc(db, 'metrics', '3m_technical_health');
    const unsubscribe3m = onSnapshot(docRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      const units = snapshot.data().total_count_units;
      const unitsWithIssues = snapshot.data().count_units_with_technical_issues;

      setData((data) => ({
        ...data,
        units,
        unitsWithIssues,
      }));
      setIsLoading3m(false);
    });

    const collectionRef = collection(db, 'investis-technical-health');
    const unsubscribeCollection = onSnapshot(collectionRef, (snapshot) => {
      if (!snapshot.empty) {
        const docs = snapshot.docs.map((doc) => [doc.id, doc.data()]);

        const daily = Object.fromEntries(docs);

        setData((data) => ({
          ...data,
          daily,
        }));
        setIsLoadingCollection(false);
      }
    });

    return () => {
      unsubscribe3m();
      unsubscribeCollection();
    };
  }, []);

  return (
    <TechnicalHealthContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </TechnicalHealthContext.Provider>
  );
};

export default TechnicalHealthProvider;
