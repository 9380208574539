import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { auth } from "../../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import pertyLogo from "../../assets/images/perty_logo.png";

import { Box, Button, styled, TextField, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../store";
import { selectMode } from "../../store/ui.slice";

type LoginProps = {
  fade?: boolean;
  onSubmit: () => void;
};

const Login: React.FC<LoginProps> = ({ fade = true, onSubmit }) => {
  const theme = useTheme();
  const mode = useAppSelector(selectMode);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const signInWithEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onSubmit();
    } catch (error: any) {
      console.error(error);
      let message = "An unexpected error occurred. Please try again.";

      if (error.code) {
        switch (error.code) {
          case "auth/user-not-found":
            message = "User not found.";
            break;
          case "auth/wrong-password":
            message = "Incorrect password. Please try again.";
            break;
          case "auth/missing-password":
            message = "A password is missing. Please try again.";
            break;
          case "auth/invalid-credential":
            message = "Incorrect email or password. Please try again.";
            break;
          case "auth/invalid-email":
            message = "The email you have provided is invalid.";
            break;
          case "auth/too-many-requests":
            message = "Login attempt failed too many times.";
            break;
        }
      }
      setErrorMessage(message);
    }
  };

  useEffect(() => {
    setErrorMessage("");
  }, [password, email]);

  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    []
  );

  const handleForgottenPassword = () => {
    window.location.href = `mailto:contact@perty.immo?subject=AMP%20Forgotten%20Password&body=Dear%20Perty%20Team,%0AI%20am%20contacting%20you%20to%20kindly%20request%20a%20password%20reset%20for%20my%20user.%0ABest%20Regards%0A`;
  };

  return (
    <LoginFormRoot
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={4}
      fade={fade}
    >
      <img
        src={pertyLogo}
        alt="Perty logo"
        height="100px"
        style={{ filter: mode === "light" ? "invert(0.8)" : "none" }}
      />
      <Box display="flex" alignItems="center" gap={1}>
        <Dot />
        <Typography variant="h6">Perty Asset Manager OS</Typography>
      </Box>
      <form style={{ width: "300px" }} onSubmit={signInWithEmail}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="subtitle1" align="center">
            Welcome back
          </Typography>
          <Typography variant="subtitle2" align="center">
            Please enter your credentials to continue
          </Typography>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <label htmlFor="email" className="Label">
              Email
            </label>
            <InputField
              variant="outlined"
              size="small"
              name="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <label htmlFor="password" className="Label">
              Password
            </label>
            <InputField
              variant="outlined"
              size="small"
              name="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="and password"
            />
            <Button
              variant="text"
              sx={{
                width: "fit-content",
                padding: 0,
                color: theme.palette.secondary.light,
                textTransform: "none",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => handleForgottenPassword()}
            >
              Forgotten Password
            </Button>
          </Box>
          <Typography
            variant="body2"
            align="center"
            color={theme.palette.error.main}
          >
            {errorMessage}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            size="small"
            disabled={!email || !password}
            color="secondary"
            sx={{
              padding: "6px",
              borderRadius: "8px",
              textTransform: "none",

              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
              },

              "&:disabled": {
                backgroundColor: theme.palette.secondary.light,
              },
            }}
          >
            Sign In
          </Button>
        </Box>
      </form>
      <Typography variant="caption">
        Made with ❤️ in Switzerland | &#169; 2024 Perty Technologies SA
      </Typography>
    </LoginFormRoot>
  );
};

const LoginFormRoot = styled(Box, {
  shouldForwardProp: (prop) => prop !== "fade",
})<{ fade: boolean }>(
  ({ fade }) => `
  opacity: ${fade ? 0 : 1};
  transition: opacity 2s ease-in-out;
`
);

const Dot = styled("span")`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.text.primary};
`;

const InputField = styled(TextField)`
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.palette.text.disabled};

  &:hover,
  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.palette.secondary.light};
  }

  & .MuiOutlinedInput-root {
    border: none;
  }

  & fieldset {
    border: none;
  }
`;

export default Login;
