import { Box, Typography } from "@mui/material";
import { FC, useContext, useMemo } from "react";
import AreaChart from "../chart/AreaChart/AreaChart";

import PageTitle from "../PageTitle/PageTitle";
import { formatDate } from "../../helpers/DateHelpers";
import { CpiIncreasesContext } from "../../context/CpiIncreasesContext";
import { formatNumber, humanizeNumber } from "../../helpers/NumberHelpers";
import { GridColDef } from "@mui/x-data-grid";
import DataGrid from "../DataGrid/DataGrid";

const CpiIncreaseDetails: FC = () => {
  const data = useContext(CpiIncreasesContext);

  const graphData = useMemo(() => {
    const entries = Object.entries(data.monthly).map(([key, value], index) => {
      const keyComponents = key.split("M");

      return {
        value1: value.success_rate,
        value2: value.total_increase_cumulative_sum,
        label: formatDate(
          new Date(
            parseInt(keyComponents[0].padStart(4, "20")),
            parseInt(keyComponents[1]) - 1,
            1
          ),
          { day: false }
        ),
      };
    });

    const rentIncreaseDomain = [
      0,
      Math.max(...entries.map((entry) => entry.value2)),
    ];

    const successRateDomain = [0, 100];

    return {
      successRateGraph: {
        data: entries.map((item) => ({
          value: item.value1,
          label: item.label,
        })),
        domain: successRateDomain,
      },
      rentIncreaseGraph: {
        data: entries.map((item) => ({
          value: item.value2,
          label: item.label,
        })),
        domain: rentIncreaseDomain,
      },
    };
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: "ref",
      headerName: "Ref",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 3,
    },
    {
      field: "rooms",
      headerName: "Rooms",
      flex: 1,
    },
    {
      field: "floor",
      headerName: "Floor",
      flex: 1,
    },
    {
      field: "main_tenant_name",
      headerName: "Main Tenant",
      flex: 2,
    },
    {
      field: "current_net_rent",
      headerName: "Old Net Rent",
      flex: 1,
    },
    {
      field: "proposed_net_rent",
      headerName: "Applied New Net Rent",
      flex: 1,
    },
  ];

  const rows = useMemo(() => {
    const thisMonthKey = `${new Date().getFullYear().toString().slice(-2)}M${(
      new Date().getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}`;
    if (!thisMonthKey) return [];
    return data.monthly[thisMonthKey].units.map((unit) => ({
      id: unit.ref,
      ref: unit.ref,
      address: `${unit.street}, ${unit.postcode} ${unit.city}`,
      rooms: unit.rooms,
      floor: unit.floor,
      main_tenant_name: unit.main_tenant_name,
      current_net_rent: `${humanizeNumber(unit.current_net_rent)} CHF`,
      proposed_net_rent: `${humanizeNumber(unit.proposed_net_rent)} CHF`,
    }));
  }, [data.monthly]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <PageTitle title="CPI Rent Increases" />
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="body2">Rent Increases Applied (%)</Typography>
        <AreaChart
          values={graphData.successRateGraph.data}
          domain={graphData.successRateGraph.domain as [number, number]}
          width="100%"
          height="30vh"
          tooltipTitle="Rent Increases Completed"
          unit="%"
          showAxisUnit={true}
          props={{
            syncId: "x",
          }}
        />
        <Typography variant="body2">Cumulative Rent Increase (CHF)</Typography>
        <AreaChart
          values={graphData.rentIncreaseGraph.data}
          domain={graphData.rentIncreaseGraph.domain as [number, number]}
          width="100%"
          height="30vh"
          tooltipTitle="Rent Increase"
          tooltipValueFormatter={(value) =>
            formatNumber({ value, options: { round: true, format: ", " } })
          }
          unit="CHF"
          props={{
            syncId: "x",
          }}
        />
      </Box>
      <Typography variant="body2">
        CPI Increases Applied from{" "}
        {formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          { year: false }
        )}
      </Typography>
      <DataGrid
        height="400px"
        columns={columns}
        rows={rows}
        disableColumnMenu={true}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        hideFooter={true}
      />
    </Box>
  );
};

export default CpiIncreaseDetails;
