import { generateRange } from "../../helpers/RangeHelper";
import { AggregatedData, Building, BuildingHeatingItem, ConsumptionAggregatedYearData, ConsumptionItem } from "./Consumption";
import { Location } from 'react-router-dom';

export const aggregateConsumptionData = (data: ConsumptionItem[]) => {
    const consumptionAggPerYear: AggregatedData = {};

    data.forEach(record => {
        const yearKey = record.year.toString();
        if (!consumptionAggPerYear[yearKey]) {
            consumptionAggPerYear[yearKey] = {
                year: record.year,
                electricity: record.electricity_total,
                gas: record.gas_total,
                water: record.water_total,
                heating: record.heating_total,
                mazout: record.mazout_total,
            }
        } else {
            consumptionAggPerYear[yearKey] = {
                year: yearKey,
                electricity: parseFloat((consumptionAggPerYear[yearKey].electricity + record.electricity_total).toFixed(2)),
                gas: parseFloat((consumptionAggPerYear[yearKey].gas + record.gas_total).toFixed(2)),
                water: parseFloat((consumptionAggPerYear[yearKey].water + record.water_total).toFixed(2)),
                heating: parseFloat((consumptionAggPerYear[yearKey].heating + record.heating_total).toFixed(2)),
                mazout: parseFloat((consumptionAggPerYear[yearKey].mazout + (record.mazout_total)).toFixed(2))
            }  
        }
    });

    const agg: ConsumptionAggregatedYearData[] = Object.values(consumptionAggPerYear);


    function calculateGasPercentage(item: ConsumptionAggregatedYearData, agg: ConsumptionAggregatedYearData[]) {
        if (item.gas === 0) {
            return 0
        }

        let validIndex = 0;
        if (agg[0].gas === 0) {
            const nonZeroIndex = agg.findIndex(a => a.gas !== 0);

            if (nonZeroIndex !== -1) {
                validIndex = nonZeroIndex;
            } else {
                return 0;
            }
        }

        return Math.trunc(item.gas / agg[validIndex].gas * 100)
    }

    function calculateElectricityPercentage(item: ConsumptionAggregatedYearData, agg: ConsumptionAggregatedYearData[]) {
        if (item.electricity === 0) {
            return 0
        }

        let validIndex = 0;
        if (agg[0].electricity === 0) {
            const nonZeroIndex = agg.findIndex(a => a.electricity !== 0);

            if (nonZeroIndex !== -1) {
                validIndex = nonZeroIndex;
            } else {
                return 0;
            }
        }

        return Math.trunc(item.electricity / agg[validIndex].electricity * 100)
    }

    function calculateWaterPercentage(item: ConsumptionAggregatedYearData, agg: ConsumptionAggregatedYearData[]) {
        if (item.water === 0) {
            return 0
        }

        let validIndex = 0;
        if (agg[0].water === 0) {
            const nonZeroIndex = agg.findIndex(a => a.water !== 0);

            if (nonZeroIndex !== -1) {
                validIndex = nonZeroIndex;
            } else {
                return 0;
            }
        }

        return Math.trunc(item.water / agg[validIndex].water * 100)
    }

    function calculateMazoutPercentage(item: ConsumptionAggregatedYearData, agg: ConsumptionAggregatedYearData[]) {
        if (item.mazout === 0) {
            return 0
        }

        let validIndex = 0;
        if (agg[0].mazout === 0) {
            const nonZeroIndex = agg.findIndex(a => a.mazout !== 0);

            if (nonZeroIndex !== -1) {
                validIndex = nonZeroIndex;
            } else {
                return 0;
            }
        }

        return Math.trunc(item.mazout / agg[validIndex].mazout * 100)
    }

    function calculateHeatingPercentage(item: ConsumptionAggregatedYearData, agg: ConsumptionAggregatedYearData[]) {
        if (item.heating === 0) {
            return 0
        }

        let validIndex = 0;
        if (agg[0].heating === 0) {
            const nonZeroIndex = agg.findIndex(a => a.heating !== 0);

            if (nonZeroIndex !== -1) {
                validIndex = nonZeroIndex;
            } else {
                return 0;
            }
        }

        return Math.trunc(item.heating / agg[validIndex].heating * 100)
    }

    const agg1: ConsumptionAggregatedYearData[] = agg.map((item, index, array) => {
        if (index === 0) {
            return { 
                year: item.year, 
                electricity: item.electricity === 0 ? 0 : 100, 
                gas: item.gas === 0 ? 0 : 100, 
                water: item.water === 0 ? 0 : 100, 
                heating: item.heating === 0 ? 0 : 100, 
                mazout: item.mazout === 0 ? 0 : 100, 
            };
        } else {
            // const prevItem = array[index - 1];
            return {
                year: item.year,
                // electricity: item.electricity === 0 ? 0 : Math.trunc(item.electricity / agg[0].electricity * 100),
                electricity: calculateElectricityPercentage(item, agg),
                // gas: item.gas === 0 ? 0 : agg[0].gas === 0 ? Math.trunc(item.gas / agg[1].gas * 100) : Math.trunc(item.gas / agg[0].gas * 100),
                gas: calculateGasPercentage(item, agg),
                // water: item.water === 0 ? 0 : Math.trunc(item.water / agg[0].water * 100),
                water: calculateWaterPercentage(item, agg),
                // heating: item.heating === 0 ? 0 : Math.trunc(item.heating / agg[0].heating * 100),
                heating: calculateHeatingPercentage(item, agg),
                // mazout: item.mazout === 0 ? 0 : Math.trunc(item.mazout / agg[1].mazout * 100),
                mazout: calculateMazoutPercentage(item, agg),
                // year: item.year,
                // electricity: prevItem.electricity === 0 ? 100 : Math.trunc(item.electricity / prevItem.electricity * 100),
                // gas: prevItem.gas === 0 ? 100 : Math.trunc(item.gas / prevItem.gas * 100),
                // water: prevItem.water === 0 ? 100 : Math.trunc(item.water / prevItem.water * 100),
                // heating: prevItem.heating === 0 ? 100 : Math.trunc(item.heating / prevItem.heating * 100),
                // mazout: prevItem.mazout === 0 ? 100 : Math.trunc(item.mazout / prevItem.mazout * 100),
            };
        }
    });


    return [agg, agg1]; 
}

export function filterAggregatedData(location: Location, data: ConsumptionItem[], buildingList: Building[]): { filteredData: ConsumptionItem[], filteredAggregatedData: ConsumptionAggregatedYearData[], agg:ConsumptionAggregatedYearData[] } {
    const searchParams = new URLSearchParams(location.search);
    const buildingIds = searchParams.getAll('building');
    let startYear = searchParams.get('start-year');
    let endYear = searchParams.get('end-year');
    const cities = searchParams.getAll('city');
    const cantons = searchParams.getAll('canton');

    startYear = startYear ?? '2017';
    endYear = endYear ?? new Date().getFullYear().toString();

    let filteredData: ConsumptionItem[] = data;

    if (buildingIds.length > 0) {
        filteredData = filteredData.filter(building => buildingIds.includes(building.building_id.toString())); 
    }

    const yearsRange = generateRange(parseInt(startYear), parseInt(endYear))
    filteredData = filteredData.filter(building => yearsRange.includes(building.year));

    filteredData = filteredData.map(consumptionItem => {
        const buildingFound = buildingList.find(building => building.id.toString() === consumptionItem.building_id.toString());
        
        if (buildingFound) {
            return {
                ...consumptionItem, 
                building_address: buildingFound.address, 
                building_city: buildingFound.city, 
                building_canton: buildingFound.canton
            }
        }
        return consumptionItem;
    });

    if (cities.length > 0) {
        filteredData = filteredData.filter(
            item => cities.includes(item.building_city as string)
        );
    }

    if (cantons.length > 0) {
        filteredData = filteredData.filter(
            item => cantons.includes(item.building_canton as string)
        );
    }


    const [agg, filteredAggregatedData] = aggregateConsumptionData(filteredData);

    return { filteredData, filteredAggregatedData, agg };
}

export function matchHeatingParamsToBuildingData( data: ConsumptionItem[], heatingData: BuildingHeatingItem[] ) {
    const filteredDataAddressHeating = data.map(consumptionItem => {
        const heatingParams = heatingData.find(building => building.building_id.toString() === consumptionItem.building_id.toString());
        
        if (heatingParams) {
            return {
                ...consumptionItem, 
                building_commune: heatingParams.commune, 
                building_sre: heatingParams.sre,
                building_energy_agent: heatingParams.energy_agent,
                electricity_m2: Math.trunc((consumptionItem.electricity_total / heatingParams.sre) *100)/100,
                gas_m2: Math.trunc((consumptionItem.gas_total / heatingParams.sre) *100)/100,
                water_m2: Math.trunc((consumptionItem.water_total / heatingParams.sre) *100)/100, 
                heating_m2: Math.trunc((consumptionItem.heating_total / heatingParams.sre) *100)/100,
                mazout_m2: Math.trunc((consumptionItem.mazout_total / heatingParams.sre) * 100)/100,
            }
        }

        return consumptionItem;
    });

    return filteredDataAddressHeating;
}