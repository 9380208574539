import { Box, styled, Typography } from "@mui/material";
import { LabelProps, TooltipProps } from "recharts";
import { useTheme } from "@mui/material/styles";

export const CustomTooltip = (
  props: TooltipProps<number, string> & { minDate?: string }
) => {
  const { active, payload, minDate } = props;

  if (active && payload && payload.length) {
    const data = payload[0].payload;

    if (minDate === data.min_date) {
      return null;
    }
    return (
      <CustomTooltipRoot
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
          <Box
            width="10px"
            height="10px"
            borderRadius="50%"
            sx={{ backgroundColor: data.color }}
          ></Box>
          <Typography variant="subtitle1">{data.value}%</Typography>
        </Box>
        <Typography variant="caption" align="center">
          {data.max_date}
        </Typography>
      </CustomTooltipRoot>
    );
  } else {
    return null;
  }
};

export const CustomLabel = (
  props: LabelProps & { minDate?: string; labelValue?: number }
) => {
  const { y, value, minDate, labelValue } = props;
  const theme = useTheme();

  if (minDate !== value) {
    return null;
  }

  return (
    <g>
      <foreignObject
        x="calc(100% - 140px)"
        y={`calc(${y}px + 40px)`}
        width="120px"
        height="64px"
      >
        <CustomTooltipRoot
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Box
              width="10px"
              height="10px"
              borderRadius="50%"
              sx={{ backgroundColor: theme.palette.primary.main }}
            ></Box>
            <Typography variant="subtitle1">{labelValue}%</Typography>
          </Box>
          <Typography variant="caption" align="center">
            Today
          </Typography>
        </CustomTooltipRoot>
      </foreignObject>
    </g>
  );
};

const CustomTooltipRoot = styled(Box)`
  border-radius: 12px;
  background: ${(props) => props.theme.cardBackground};
  backdrop-filter: blur(10px);
  padding: 8px;
`;
