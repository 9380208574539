import { collection, onSnapshot } from 'firebase/firestore';
import { createContext, useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import Loading from '../components/Loading/Loading';

type RentIncreasesMonthlyData = {
  cumulative_rent_change_2023: number;
  cumulative_rent_change_2024: number;
  relocations_count: number;
  relocations_without_increase: number;
  rent_change: 6.15;
};

type RentIncreasesData = {
  monthly: {
    [month: string]: RentIncreasesMonthlyData;
  };
  currentRelocationsIncreased: number;
  currentCumulativeRentIncreased: number;
  currentRelocationsCount: number;
};

const initialValue: RentIncreasesData = {
  monthly: {},
  currentRelocationsIncreased: 0,
  currentCumulativeRentIncreased: 0,
  currentRelocationsCount: 0,
};

export const RentIncreasesContext =
  createContext<RentIncreasesData>(initialValue);

type RentIncreasesProviderProps = {
  children: React.ReactNode;
};

const RentIncreasesProvider: React.FC<RentIncreasesProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<RentIncreasesData>(initialValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const ref = collection(db, 'investis-reloc-increases');
    const unsubscribe = onSnapshot(ref, (snapshot) => {
      if (snapshot.empty) {
        return;
      }

      const monthly = Object.fromEntries(
        snapshot.docs.map((doc) => [
          doc.id,
          doc.data() as RentIncreasesMonthlyData,
        ])
      );

      const currentMonth = Object.values(monthly).at(-1);
      if (!currentMonth) {
        return;
      }
      const currentRelocationsCount = currentMonth.relocations_count;
      const currentRelocationsIncreased =
        ((currentMonth.relocations_count -
          currentMonth.relocations_without_increase) /
          currentMonth.relocations_count) *
        100;
      const currentCumulativeRentIncreased =
        currentMonth.cumulative_rent_change_2024;

      setData({
        monthly,
        currentRelocationsCount,
        currentRelocationsIncreased,
        currentCumulativeRentIncreased,
      });
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <RentIncreasesContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </RentIncreasesContext.Provider>
  );
};

export default RentIncreasesProvider;
