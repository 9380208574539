import React, { useContext, useMemo, useState } from "react";
import PageTitle from "../PageTitle/PageTitle";

import MetricCardSimple from "../MetricCardSimple/MetricCardSimple";

import { GuaranteeContext } from "../../context/GuaranteeContext";
import AreaChart from "../chart/AreaChart/AreaChart";
import { Box, Grid, styled, Typography } from "@mui/material";
import {
  faClockRotateLeft,
  faUmbrellaBeach,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendar, faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { formatDate } from "../../helpers/DateHelpers";
import { formatNumber } from "../../helpers/NumberHelpers";

enum TimePeriod {
  L7D = "L7D",
  L1W = "L1W",
  L1M = "L1M",
  L6M = "L6M",
  L30D = "L30D",
  YTD = "YTD",
}

const Guarantee: React.FC = () => {
  const [timePeriod, setTimePeriod] = useState<
    TimePeriod.L1M | TimePeriod.L30D
  >(TimePeriod.L1M);

  const guaranteeData = useContext(GuaranteeContext);

  const currentData = useMemo(
    () => ({
      onTimeResRate: guaranteeData[timePeriod]?.on_time_response_rate,
      averageResponseTime: guaranteeData[timePeriod]?.avg_res_time,
      sameDayResponse: guaranteeData[timePeriod]?.same_day_res_rate,
      outsideWorkingHoursRate:
        guaranteeData[timePeriod]?.outside_working_hours_rate,
      minDate: guaranteeData[timePeriod]?.min_date,
      maxDate: guaranteeData[timePeriod]?.max_date,
    }),
    [guaranteeData, timePeriod]
  );

  const graphData = useMemo(
    () =>
      guaranteeData.weeklyData.slice(-12).map((item) => ({
        value: item.on_time_response_rate,
        label: item.max_date
          ? formatDate(item.max_date.toDate(), { year: false })
          : "",
        tooltipLabel:
          item.min_date && item.max_date
            ? `${formatDate(item.min_date.toDate(), {
                year: false,
              })} - ${formatDate(item.max_date.toDate(), { year: false })}`
            : "",
        unit: "%",
      })),
    [guaranteeData.weeklyData]
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <PageTitle title="24h Guarantee" />
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1">
          Tenant Issues Responded to in the first 24h
        </Typography>
        <AreaChart
          values={graphData}
          domain={[0, 100]}
          width="100%"
          height="35vh"
          tooltipTitle="Completed in < 24h"
          tooltipValueFormatter={(value: number) =>
            formatNumber({ value, options: { round: false } })
          }
          unit="%"
          showAxisUnit={true}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <TimePeriodSwitch>
            <Grid container>
              <TimePeriodSwitchItem
                item
                xs={6}
                selected={timePeriod === TimePeriod.L1M}
                onClick={() => setTimePeriod(TimePeriod.L1M)}
              >
                Last Invoice Period
              </TimePeriodSwitchItem>
              <TimePeriodSwitchItem
                item
                xs={6}
                selected={timePeriod === TimePeriod.L30D}
                onClick={() => setTimePeriod(TimePeriod.L30D)}
              >
                Last 30 Days
              </TimePeriodSwitchItem>
            </Grid>
          </TimePeriodSwitch>
          {currentData.minDate && currentData.maxDate ? (
            <Typography variant="caption">
              Date Range: &nbsp;
              {formatDate(currentData.minDate.toDate())} &rarr;{" "}
              {formatDate(currentData.maxDate.toDate())}
            </Typography>
          ) : null}
        </Box>
        <Box display="grid" gridAutoColumns="1fr" gridAutoFlow="column" gap={3}>
          <MetricCardSimple
            title="Response < 24h"
            value={currentData.onTimeResRate ?? ""}
            caption="responded to in less than 24h"
            unit="%"
            icon={faClockRotateLeft}
            variant="inverse"
            disableHover
          />
          <MetricCardSimple
            title="Average Response Time"
            value={currentData.averageResponseTime ?? ""}
            caption="Avg. time Perty takes to respond"
            unit=""
            icon={faCommentDots}
            disableHover
          />
          <MetricCardSimple
            title="Same Day Response"
            value={currentData.sameDayResponse ?? ""}
            caption="responded to on the same day"
            unit="%"
            icon={faCalendar}
            disableHover
          />
          <MetricCardSimple
            title="Requests Outside Working Hours"
            value={currentData.outsideWorkingHoursRate ?? ""}
            caption="submitted outside working hours and on weekends"
            unit="%"
            icon={faUmbrellaBeach}
            disableHover
          />
        </Box>
      </Box>
    </Box>
  );
};

const TimePeriodSwitch = styled("div")(
  ({ theme }) => `
width: 260px;
z-index: 1;
border-radius: 8px;
overflow: hidden;
background: ${theme.cardBackground};
box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);

`
);

const TimePeriodSwitchItem = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{
  selected?: boolean;
}>(
  ({ theme, selected }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  font-size: ${theme.typography.caption.fontSize};
  font-weight: ${selected ? "bold" : "normal"};
  cursor: pointer;
  height: 24px;

  border-bottom: solid;
  border-bottom-width: ${selected ? "2px" : "0px"};
  border-bottom-color: ${theme.palette.secondary.main};
  box-sizing: content-box;
`
);

export default Guarantee;
