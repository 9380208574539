import React from "react";
import { Area, AreaChart, ResponsiveContainer, YAxis } from "recharts";
import { Box, styled, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export interface GraphData {
  data: { value: number }[];
  startLabel: string;
  endLabel: string;
  domain: string[];
}

interface PortfolioHealthMetricCardProps {
  title: string;
  value: string | number;
  caption: string;
  valueAssessment: string;
  graphData?: GraphData;
  unit?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const PortfolioHealthMetricCard: React.FC<PortfolioHealthMetricCardProps> = ({
  title,
  value,
  caption,
  graphData,
  unit,
  onClick,
}) => {
  const theme = useTheme();
  const domain =
    graphData?.domain[0] === "dataMin - 0" &&
    graphData?.domain[1] === "dataMax + 0"
      ? ["dataMin - 1", "dataMax + 1"]
      : graphData?.domain;

  return (
    <>
      <PortfolioHealthMetricCardRoot
        className="PortfolioHealthMetricCard"
        onClick={onClick}
      >
        <MetricCardContent display="flex" flexDirection="column">
          <Typography variant="subtitle1">{title}</Typography>
          <Box display="flex" gap={0.5} marginTop={2}>
            <Typography variant="h4">{value}</Typography>
            {unit ? (
              <Typography
                component="span"
                variant="body1"
                sx={{ verticalAlign: "top" }}
              >
                {unit}
              </Typography>
            ) : null}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">{caption}</Typography>
          </Box>
        </MetricCardContent>
        <Box width="100%" height={60}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={graphData?.data}
              margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
            >
              <YAxis domain={domain} hide />
              <defs>
                <radialGradient id="metricCardUv" cx="100%" cy="0" r="100%">
                  <stop offset="30%" stopColor={theme.gradient.lineChart[0]} />
                  <stop offset="60%" stopColor={theme.gradient.lineChart[1]} />
                  <stop offset="100%" stopColor={theme.gradient.lineChart[2]} />
                </radialGradient>
              </defs>
              <Area
                type="linear"
                dataKey="value"
                stroke={theme.palette.primary.main}
                fill="url(#metricCardUv)"
                strokeWidth={2}
                dot={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
        <GraphLabelsContainer
          width="100%"
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="caption">{graphData?.startLabel}</Typography>
          <Typography variant="caption">{graphData?.endLabel}</Typography>
        </GraphLabelsContainer>
      </PortfolioHealthMetricCardRoot>
    </>
  );
};

const PortfolioHealthMetricCardRoot = styled(Box)(
  ({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
  background: ${theme.cardBackground};

  border-radius: 12px;

  cursor: pointer;
  transition: 0.1s ease-out;

  &:hover {
  transform: scale(1.01);
  background: ${theme.cardBackground.replace("0.2", "0.3")};
  }
`
);

const MetricCardContent = styled(Box)`
  padding: 20px 20px 0;
`;

const GraphLabelsContainer = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
`
);

export default PortfolioHealthMetricCard;
