import React from "react";
import pertyLogo from "../../assets/images/perty_logo.png";
import { Box, styled, Typography } from "@mui/material";

const Loading: React.FC = () => {
  return (
    <LoadingRoot
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={3}
    >
      <Logo src={pertyLogo} alt="Perty logo" />
      <Typography variant="subtitle1">Loading Content...</Typography>
    </LoadingRoot>
  );
};

const LoadingRoot = styled(Box)`
  color: ${({ theme }) => theme.palette.text.primary};
  z-index: 9999999999999999;
`;

const Logo = styled("img")`
  height: 100px;
  filter: ${({ theme }) => theme.iconFilter ?? "none"};
`;

export default Loading;
