import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    primary: {
      dark: 'rgba(157, 34, 170, 1)',
      main: 'rgba(196, 43, 213, 1)',
      light: 'rgba(208, 85, 221, 1)',
      100: 'rgba(243, 212, 247, 0.1)',
      200: 'rgba(231, 170, 238, 0.2)',
      300: 'rgba(219, 127, 230, 0.3)',
      400: 'rgba(208, 85, 221, 0.3)',
      500: 'rgba(196, 43, 213, 0.4)',
      600: 'rgba(157, 34, 170, 0.4)',
      700: 'rgba(117, 25, 128, 0.5)',
      800: 'rgba(78, 17, 85, 0.5)',
      900: 'rgba(39, 8, 43, 0.5)',
    },
    secondary: {
      dark: 'rgba(217, 12, 91, 1)',
      main: 'rgba(243, 44, 121, 1)',
      light: 'rgba(245, 86, 147, 1)',
      100: 'rgba(253, 212, 227, 0.1)',
      200: 'rgba(250, 173, 203, 0.2)',
      300: 'rgba(248, 130, 175, 0.3)',
      400: 'rgba(245, 86, 147, 0.3)',
      500: 'rgba(243, 44, 121, 0.4)',
      600: 'rgba(217, 12, 91, 0.4)',
      700: 'rgba(164, 9, 69, 0.5)',
      800: 'rgba(111, 6, 46, 0.5)',
      900: 'rgba(53, 3, 22, 0.5)',
    },
    shadow: {
      primary: 'rgba(16, 24, 40, 0.08)',
    },
    success: {
      main: 'rgba(103, 208, 159, 1)',
      light: 'rgba(59, 227, 182, 1)',
      dark: 'rgba(49, 160, 108, 1)',
      100: 'rgba(216, 243, 230, 0.1)',
      200: 'rgba(181, 232, 208, 0.2)',
      300: 'rgba(142, 220, 184, 0.3)',
      400: 'rgba(103, 208, 159, 0.3)',
      500: 'rgba(65, 197, 136, 0.4)',
      600: 'rgba(49, 160, 108, 0.4)',
      700: 'rgba(37, 121, 82, 0.5)',
      800: 'rgba(25, 82, 55, 0.5)',
      900: 'rgba(12, 39, 26, 0.5)',
    },
    grey: {
      200: 'rgba(176, 178, 196, 1)',
      600: 'rgba(107, 109, 128, 1)',
    },
    background: {
      default: 'rgba(28, 17, 48, 1)',
      paper: 'white',
    },
    text: {
      primary: '#FFF',
      secondary: '#FFF',
      disabled: '#b0b2c4',
    },
    divider: '#484954',
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '72px',
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 600,
    },
    h4: { fontWeight: 600 },
    h5: { fontWeight: 600 },
    h6: { fontWeight: 500 },
    subtitle1: { fontWeight: 500 },
    subtitle2: { fontWeight: 500 },
  },
  gradient: {
    lineChart: ['#C42BD533', '#d655e41A', '#d655e400'],
  },
  background: 'linear-gradient(130deg, #160527 0%, #0a2d46 100%)',
  cardBackground: 'rgba(255, 255, 255, 0.2)',
  chartPalette: [
    '#dc698c',
    '#e9507e',
    '#F32C79',
    '#C42BD5',
    ' #d655e4',
    '#7f67f5',
    '#636bdb',
    '#95a4cd',
  ],
  hyperspaceColor: '#d089f1',
});

export const lightTheme = createTheme({
  palette: {
    primary: {
      dark: 'rgba(69, 36, 234, 1)',
      main: 'rgba(123, 99, 240, 1)',
      light: 'rgba(148, 129, 243, 1)',
      100: 'rgba(227, 222, 252, 0.8)',
      200: 'rgba(204, 195, 249, 0.8)',
      300: 'rgba(176, 162, 246, 0.6)',
      400: 'rgba(148, 129, 243, 0.3)',
      500: 'rgba(123, 99, 240, 0.2)',
      600: 'rgba(69, 36, 234, 0.2)',
      700: 'rgba(46, 18, 186, 0.1)',
      800: 'rgba(31, 12, 126, 0.05)',
      900: 'rgba(15, 6, 60, 0.02)',
    },
    secondary: {
      dark: 'rgba(209, 21, 90, 1)',
      main: 'rgba(235, 54, 120, 1)',
      light: 'rgba(239, 93, 146, 1)',
      100: 'rgba(251, 213, 227, 0.8)',
      200: 'rgba(247, 176, 202, 0.8)',
      300: 'rgba(243, 134, 174, 0.6)',
      400: 'rgba(239, 93, 146, 0.3)',
      500: 'rgba(235, 54, 120, 0.2)',
      600: 'rgba(209, 21, 90, 0.2)',
      700: 'rgba(158, 16, 68, 0.1)',
      800: 'rgba(107, 11, 46, 0.05)',
      900: 'rgba(51, 5, 22, 0.02)',
    },
    common: {
      white: '#FFF',
    },
    shadow: {
      primary: 'rgba(16, 24, 40, 0.08)',
    },
    success: {
      main: 'rgba(103, 208, 159, 1)',
      light: 'rgba(59, 227, 182, 1)',
      dark: 'rgba(49, 160, 108, 1)',
      100: 'rgba(216, 243, 230, 0.8)',
      200: 'rgba(181, 232, 208, 0.8)',
      300: 'rgba(142, 220, 184, 0.6)',
      400: 'rgba(103, 208, 159, 0.3)',
      500: 'rgba(65, 197, 136, 0.2)',
      600: 'rgba(49, 160, 108, 0.2)',
      700: 'rgba(37, 121, 82, 0.1)',
      800: 'rgba(25, 82, 55, 0.05)',
      900: 'rgba(12, 39, 26, 0.02)',
    },
    grey: {
      200: 'rgba(176, 178, 196, 1)',
      600: 'rgba(107, 109, 128, 1)',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    text: {
      primary: '#393844',
      secondary: '#686871',
      disabled: '#9898A0',
    },
    divider: '#c5c7d4',
    action: {
      selected: '#f24085',
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '72px',
      fontWeight: 'bold',
      color: '#393844',
    },
    h2: {
      fontWeight: 'bold',
      color: '#393844',
    },
    h3: {
      fontWeight: 600,
      color: '#393844',
    },
    h4: { fontWeight: 600, color: '#393844' },
    h5: { fontWeight: 600, color: '#393844' },
    h6: { fontWeight: 500, color: '#393844' },
    subtitle1: { fontWeight: 500, color: '#393844' },
    subtitle2: { fontWeight: 500, color: '#393844' },
    body1: { color: '#686871' },
    body2: { color: '#686871' },
    caption: {
      color: '#9898A0',
    },
  },
  gradient: {
    lineChart: ['#7b63f033', '#a192ed0f', '#a192ed00'],
  },
  background: '#ffffffff',
  cardBackground: '#cdbcf521',
  chartPalette: [
    '#dc698c',
    '#e9507e',
    '#ed4baf',
    '#b455e4',
    '#8e2bd5',
    '#7f67f5',
    '#636bdb',
    '#95a4cd',
  ],
  iconFilter: 'invert(0.7)',
});
