import { Box } from '@mui/material';
import { DataGrid as MuiDataGrid, DataGridProps } from '@mui/x-data-grid';
import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from '../../store';
import { selectMode } from '../../store/ui.slice';

const DataGrid = forwardRef<
  HTMLDivElement,
  DataGridProps & { height: string | number }
>(({ rows, columns, height, ...props }, ref) => {
  const mode = useAppSelector(selectMode);
  const theme = useTheme();
  return (
    <Box height={height}>
      <MuiDataGrid
        ref={ref}
        rows={rows}
        columns={columns}
        rowSelection={false}
        {...props}
        sx={{
          boxShadow: '3px 3px 10px 1px rgba(16, 24, 40, 0.05)',
          borderRadius: '12px',
          overflow: 'hidden',
          '&, & .MuiDataGrid-cell, .MuiDataGrid-withBorderColor ': {
            borderColor: theme.palette.divider,
          },
          '.MuiDataGrid-filler > div': {
            borderTop: `1px solid ${theme.palette.divider}`,
          },
          '.MuiDataGrid-sortIcon': {
            color: theme.palette.text.primary,
          },
          '.MuiDataGrid-topContainer:after': {
            display: 'none',
          },
          '.MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within':
            {
              outline: 'none',
            },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '.MuiDataGrid-columnHeader, .MuiDataGrid-scrollbarFiller--header': {
            backgroundColor:
              mode === 'light'
                ? theme.cardBackground
                : theme.palette.background.default,
          },
          '.MuiDataGrid-columnHeaderTitle': {
            color: theme.palette.text.secondary,
          },
          '.over': {
            backgroundColor: theme.palette.secondary[200],

            '&:hover': {
              backgroundColor: theme.palette.secondary[300],
            },
          },
          '.nearing': {
            backgroundColor: theme.palette.secondary[100],

            '&:hover': {
              backgroundColor: theme.palette.secondary[200],
            },
          },
          ...props.sx,
        }}
      />
    </Box>
  );
});

export default DataGrid;
