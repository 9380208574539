import React, { useContext, useMemo } from 'react';

import { Box, styled, Typography } from '@mui/material';
import { PortfolioHealthContext } from '../../context/PortfolioHealthContext';
import { Dot } from './Dot';

const Home: React.FC = () => {
  const portfolioHealth = useContext(PortfolioHealthContext);

  const variant = useMemo(() => {
    if (!portfolioHealth.current) {
      return 'success';
    }
    if (portfolioHealth.current < 61) {
      return 'secondary';
    } else if (portfolioHealth.current < 86) {
      return 'primary';
    } else {
      return 'success';
    }
  }, [portfolioHealth]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        width="100%"
        height="calc(100vh - 124px)"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={8}
      >
        <Dot value={Math.round(portfolioHealth.current)} variant={variant} />
        <Box display="flex" gap={4}>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <CircleLegendDot width={10} height={10} variant="secondary" />
              <Typography variant="body2">Low</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Box display="flex">
                <Typography variant="h6" fontWeight="bold">
                  0
                </Typography>
                <Typography variant="caption" lineHeight="24px">
                  %
                </Typography>
              </Box>
              <Typography variant="h6" fontWeight="bold">
                -
              </Typography>
              <Box display="flex">
                <Typography variant="h6" fontWeight="bold">
                  60
                </Typography>
                <Typography variant="caption" lineHeight="24px">
                  %
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <CircleLegendDot width={10} height={10} variant="primary" />
              <Typography variant="body2">Good</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Box display="flex">
                <Typography variant="h6" fontWeight="bold">
                  61
                </Typography>
                <Typography variant="caption" lineHeight="24px">
                  %
                </Typography>
              </Box>
              <Typography variant="h6" fontWeight="bold">
                -
              </Typography>
              <Box display="flex">
                <Typography variant="h6" fontWeight="bold">
                  85
                </Typography>
                <Typography variant="caption" lineHeight="24px">
                  %
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <CircleLegendDot width={10} height={10} variant="success" />
              <Typography variant="body2">Excellent</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Box display="flex">
                <Typography variant="h6" fontWeight="bold">
                  86
                </Typography>
                <Typography variant="caption" lineHeight="24px">
                  %
                </Typography>
              </Box>
              <Typography variant="h6" fontWeight="bold">
                -
              </Typography>
              <Box display="flex">
                <Typography variant="h6" fontWeight="bold">
                  100
                </Typography>
                <Typography variant="caption" lineHeight="24px">
                  %
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CircleLegendDot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'primary' | 'success' | 'secondary' }>(
  ({ theme, variant }) => `
  margin-left: -18px;
  border-radius: 50%;
  background-color: ${theme.palette[variant].main};
`
);

export default Home;
