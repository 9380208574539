import { Box, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

type DotProps = {
  value: number;
  variant: 'primary' | 'secondary' | 'success';
};

export const Dot: FC<DotProps> = ({ value, variant }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const handleCardClick = (target: string) => {
    navigate(`/${target}`);
  };
  return (
    <DotRoot
      position="relative"
      width="300px"
      height="300px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      variant={variant}
      onClick={() => handleCardClick('portfolio-health')}
    >
      <Box
        width={340}
        height={340}
        top={-20}
        left={-20}
        position="absolute"
        zIndex={-2}
      ></Box>
      <Box position="relative">
        <Typography variant="h1" color={theme.palette.common.white}>
          {value}
        </Typography>
        <Typography
          component="span"
          variant="h5"
          color={theme.palette.common.white}
          sx={{ position: 'absolute', top: 8, left: '100%' }}
        >
          %
        </Typography>
      </Box>
      <Typography variant="subtitle1" color={theme.palette.common.white}>
        Portfolio Health
      </Typography>
      <Typography variant="subtitle2" color={theme.palette.common.white}>
        Today
      </Typography>
    </DotRoot>
  );
};

const DotRoot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{
  variant: 'secondary' | 'primary' | 'success';
}>(
  ({ theme, variant }) => `
  border-radius: 300px;
  opacity: 1;
  background: white;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  z-index: 10;

  &:hover {
    transform: scale(1.02);
  }

  &:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 300px;
  background: linear-gradient(
    26deg,
    ${theme.palette[variant].dark} 30%,
    ${theme.palette[variant].light} 76%
  );
  box-shadow: -1px 18px 2px 20px ${theme.palette[variant][400]},
    13px 20px 2px 20px ${theme.palette[variant][500]},
    14px -20px 2px 20px ${theme.palette[variant][300]},
    -21px 46px 25px 0px ${theme.palette[variant][100]},
    3px -4px 25px 2px ${theme.palette[variant][200]},
    -20px 8px 2px 0px ${theme.palette[variant][600]},
    4px 3px 2px 0px ${theme.palette[variant][700]},
    -15px -11px 1px 0px ${theme.palette[variant][800]},
    3px -7px 2px 0px ${theme.palette[variant][900]};
  animation: spinShadow 10s infinite;
  z-index: -1;
  filter: blur(2px);
  }

  @keyframes spinShadow {
  0% {
    box-shadow: -1px 18px 2px 0px ${theme.palette[variant][400]},
      13px 20px 2px 0px ${theme.palette[variant][500]},
      14px -20px 2px 0px ${theme.palette[variant][300]},
      -21px 46px 25px 0px ${theme.palette[variant][100]},
      3px -4px 25px 2px ${theme.palette[variant][200]},
      -20px 8px 2px 0px ${theme.palette[variant][600]},
      4px 3px 2px 0px ${theme.palette[variant][700]},
      -15px -11px 1px 0px ${theme.palette[variant][800]},
      3px -7px 2px 0px ${theme.palette[variant][900]};
  }

  25% {
    box-shadow: 18px -1px 2px 0px ${theme.palette[variant][400]},
      -20px 13px 2px 0px ${theme.palette[variant][500]},
      20px 14px 2px 0px ${theme.palette[variant][300]},
      -46px -21px 25px 0px ${theme.palette[variant][100]},
      4px -3px 25px 2px ${theme.palette[variant][200]},
      -8px -20px 2px 0px ${theme.palette[variant][600]},
      -3px -4px 2px 0px ${theme.palette[variant][700]},
      11px 15px 1px 0px ${theme.palette[variant][800]},
      7px 3px 2px 0px ${theme.palette[variant][900]};
  }
  50% {
    box-shadow: 1px -18px 2px 0px ${theme.palette[variant][400]},
      13px -20px 2px 0px ${theme.palette[variant][500]},
      -14px -20px 2px 0px ${theme.palette[variant][300]},
      21px -46px 25px 0px ${theme.palette[variant][100]},
      -3px -4px 25px 2px ${theme.palette[variant][200]},
      20px -8px 2px 0px ${theme.palette[variant][600]},
      4px 3px 2px 0px ${theme.palette[variant][700]},
      -15px -11px 1px 0px ${theme.palette[variant][800]},
      -3px 7px 2px 0px ${theme.palette[variant][900]};
  }
  75% {
    box-shadow: -18px 1px 2px 0px ${theme.palette[variant][400]},
      20px -13px 2px 0px ${theme.palette[variant][500]},
      -20px 14px 2px 0px ${theme.palette[variant][300]},
      46px 21px 25px 0px ${theme.palette[variant][100]},
      3px 4px 25px 2px ${theme.palette[variant][200]},
      8px 20px 2px 0px ${theme.palette[variant][600]},
      3px -4px 2px 0px ${theme.palette[variant][700]},
      -11px -15px 1px 0px ${theme.palette[variant][800]},
      -7px -3px 2px 0px ${theme.palette[variant][900]};
  }
  100% {
    box-shadow: -1px 18px 2px 0px ${theme.palette[variant][400]},
      13px 20px 2px 0px ${theme.palette[variant][500]},
      14px -20px 2px 0px ${theme.palette[variant][300]},
      -21px 46px 25px 0px ${theme.palette[variant][100]},
      3px -4px 25px 2px ${theme.palette[variant][200]},
      -20px 8px 2px 0px ${theme.palette[variant][600]},
      4px 3px 2px 0px ${theme.palette[variant][700]},
      -15px -11px 1px 0px ${theme.palette[variant][800]},
      3px -7px 2px 0px ${theme.palette[variant][900]};
  }
}
`
);
