import React, { useContext, useEffect, useMemo, useState } from "react";
import PageTitle from "../PageTitle/PageTitle";
import PortfolioHealthMetricCard from "./PortfolioHealthMetricCard";
import {
  Area,
  AreaChart,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GuaranteeContext } from "../../context/GuaranteeContext";
import { CustomLabel, CustomTooltip } from "./PortfolioHealthCustomElements";
import { Outlet, useNavigate } from "react-router-dom";
import { OccupancyContext } from "../../context/OccupancyContext";
import { Box, styled, Typography } from "@mui/material";
import { BudgetContext } from "../../context/BudgetContext";
import { PortfolioHealthContext } from "../../context/PortfolioHealthContext";
import { TechnicalHealthContext } from "../../context/TechnicalHealthContext";
import { RentCollectedContext } from "../../context/RentCollectedContext";
import { RentIncreasesContext } from "../../context/RentIncreasesContext";
import { CpiIncreasesContext } from "../../context/CpiIncreasesContext";
import { formatDate } from "../../helpers/DateHelpers";
import { humanizeNumber } from "../../helpers/NumberHelpers";
import ModeSwitch from "../ModeSwitch/ModeSwitch";
import ChartContainer from "../chart/ChartContainer";
import { useTheme } from "@mui/material/styles";

const PortfolioHealth: React.FC = () => {
  const theme = useTheme();
  const technicalHealth = useContext(TechnicalHealthContext);
  const portfolioHealth = useContext(PortfolioHealthContext);
  const occupancy = useContext(OccupancyContext);
  const budget = useContext(BudgetContext);
  const guarantee = useContext(GuaranteeContext);
  const rcpm = useContext(RentCollectedContext);
  const aiurc = useContext(RentIncreasesContext);
  const cpi = useContext(CpiIncreasesContext);
  const [mode, setMode] = useState("%");

  const [chartData, setChartData] = useState<
    { date: string; value: number; min_date: string; max_date: string }[]
  >([]);
  const [chartXTicks, setChartXTicks] = useState<string[]>([]);
  const [chartYTicks, setChartYTicks] = useState<[number, number, number]>([
    0, 50, 100,
  ]);

  const navigate = useNavigate();

  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();

  useEffect(() => {
    if (!Object.keys(portfolioHealth.daily).length) return;
    const values = [
      ...Object.values(portfolioHealth.daily)
        .slice(-80)
        .map((value) => {
          const keyComponents = value.date.split("-").map((c) => parseInt(c));
          const date = new Date(
            keyComponents[0],
            keyComponents[1] - 1,
            keyComponents[2]
          );
          return {
            value: value.value,
            min_date: formatDate(date),
            max_date: formatDate(date),
            date: formatDate(date, { year: false }),
            color: theme.palette.primary.main,
          };
        }),
    ];

    setChartData(values);

    const min = Math.min(...values.map((item) => item.value));
    const minTick = Math.ceil(min / 10) * 10 - 20;
    setChartYTicks([minTick, 100 - (100 - minTick) / 2, 100]);

    const ticks = [
      values[0].min_date.slice(0, -3),
      ...values
        .filter((item) => item.min_date.startsWith("1-"))
        .map((item) => {
          return formatDate(new Date(item.min_date), {
            year: false,
          });
        }),
    ];
    setChartXTicks(ticks);
  }, [portfolioHealth, portfolioHealth.weekly, theme.palette.primary.main]);

  const occupancyGraphData = useMemo(() => {
    if (!occupancy.monthly.length) return;
    const data = occupancy.monthly.slice(-7).map((item) => ({
      value: mode === "%" ? item.occupancy : item.units_vacant_count,
    }));
    const domainFactor = Math.ceil(
      Math.max(...data.map((item) => item.value)) -
        Math.min(...data.map((item) => item.value))
    );
    return {
      data,
      startLabel: formatDate(new Date(thisYear, thisMonth - 4, -1)),
      endLabel: "Today",
      domain: [`dataMin - ${domainFactor}`, `dataMax + ${domainFactor}`],
    };
  }, [mode, occupancy.monthly, thisMonth, thisYear]);

  const guaranteeChartData = useMemo(() => {
    if (!guarantee.weeklyData.length) return;
    const entries = guarantee.weeklyData
      .filter((item) => item.id.includes("CW"))
      .slice(-5, -1);
    const data = entries.map((item) => ({
      value:
        mode === "%"
          ? item.on_time_response_rate
          : item.avg_res_time
              .replace("h", "")
              .split(":")
              .reduce(
                (prev, cur, index) =>
                  index === 0
                    ? prev + parseInt(cur) * 60
                    : prev + parseInt(cur),
                0
              ),
    }));
    const domainFactor = Math.ceil(
      Math.max(...data.map((item) => item.value as number)) -
        Math.min(...data.map((item) => item.value as number))
    );

    return {
      data,
      startLabel: formatDate(entries[0].min_date?.toDate() ?? new Date()),
      endLabel: formatDate(entries.at(-1)?.max_date?.toDate() ?? new Date()),
      domain: [`dataMin - ${domainFactor}`, `dataMax + ${domainFactor}`],
    };
  }, [guarantee.weeklyData, mode]);

  const rcpmChartData = useMemo(() => {
    if (Object.keys(rcpm.rentCollectedMonthly).length === 0) return;
    const monthKey = `${thisYear.toString().slice(-2)}M${thisMonth
      .toString()
      .padStart(2, "0")}`;
    const rentDue = rcpm.rentDueMonthly[monthKey];
    const data = Object.values(rcpm.rentCollectedMonthly[monthKey]).map(
      (value) => ({
        value: mode === "%" ? (value / rentDue) * 100 : rentDue - value,
      })
    );

    const domainFactor = Math.ceil(
      Math.max(...data.map((item) => item.value as number)) -
        Math.min(...data.map((item) => item.value as number))
    );

    const startLabel = formatDate(new Date(thisYear, thisMonth - 1, 1));
    const endLabel = "Today";

    return {
      data: data,
      startLabel,
      endLabel,
      domain: [`dataMin - ${domainFactor}`, `dataMax + ${domainFactor}`],
    };
  }, [
    mode,
    rcpm.rentCollectedMonthly,
    rcpm.rentDueMonthly,
    thisMonth,
    thisYear,
  ]);

  const aiurcChartData = useMemo(() => {
    if (Object.keys(aiurc.monthly).length === 0) return;
    const data = Object.entries(aiurc.monthly)
      .filter(([key]) => key.includes(thisYear.toString().slice(-2)))
      .map(([, value]) => ({
        value:
          mode === "%"
            ? ((value.relocations_count - value.relocations_without_increase) /
                value.relocations_count) *
              100
            : value.cumulative_rent_change_2024,
      }));
    const domainFactor = Math.ceil(
      Math.max(...data.map((item) => item.value)) -
        Math.min(...data.map((item) => item.value))
    );
    const startLabel = formatDate(new Date(thisYear, 0, 1));
    const endLabel = formatDate(new Date(thisYear, thisMonth, 0));

    return {
      data,
      startLabel,
      endLabel,
      domain: [`dataMin - ${domainFactor}`, `dataMax + ${domainFactor}`],
    };
  }, [aiurc.monthly, mode, thisMonth, thisYear]);

  const techHealthChartData = useMemo(() => {
    if (!Object.keys(technicalHealth.daily).length) return;
    const reducedData = [];
    const data = Object.values(technicalHealth.daily).map((value) => ({
      value:
        mode === "%" ? 100 - value.technical_health : value.technical_health,
    }));
    for (let index = 0; index < data.length; index += 40) {
      reducedData.push(data[index]);
      if (index + 7 >= data.length) {
        const lastDatapoint = data.at(-1);
        if (lastDatapoint) {
          reducedData.push(lastDatapoint);
        }
        break;
      }
    }
    const domainFactor = Math.ceil(
      Math.max(...reducedData.map((item) => item.value)) -
        Math.min(...reducedData.map((item) => item.value))
    );
    return {
      data: reducedData,
      startLabel: "1-Jan-23",
      endLabel: "Today",
      domain: [`dataMin - ${domainFactor}`, `dataMax + ${domainFactor}`],
    };
  }, [mode, technicalHealth]);

  const cpiChartData = useMemo(() => {
    if (Object.keys(cpi.monthly).length === 0) return;

    if (mode === "%") {
      const data = Object.entries(cpi.monthly)
        .filter(([key]) => key.includes(thisYear.toString().slice(-2)))
        .slice(0, -1)
        .map(([, value]) => ({
          value: value.success_rate,
        }));
      const startLabel = formatDate(new Date(thisYear, 0, 1));
      const endLabel = formatDate(new Date(thisYear, thisMonth, 1));
      const domainFactor = Math.ceil(
        Math.max(...data.map((item) => item.value)) -
          Math.min(...data.map((item) => item.value))
      );
      return {
        data,
        startLabel,
        endLabel,
        domain: [`dataMin - ${domainFactor}`, `dataMax + ${domainFactor}`],
      };
    } else {
      const data = Object.entries(cpi.monthly)
        .filter(
          ([key]) =>
            key.includes(new Date().getFullYear().toString().slice(-2)) &&
            parseInt(key.slice(-2)) <= new Date().getMonth() + 1
        )
        .map(([, value]) => ({ value: value.total_increase_cumulative_sum }));
      const startLabel = formatDate(new Date(thisYear, 0, 1));
      const endLabel = formatDate(new Date(thisYear, thisMonth));
      const domainFactor = Math.ceil(
        Math.max(...data.map((item) => item.value)) -
          Math.min(...data.map((item) => item.value))
      );
      return {
        data,
        startLabel,
        endLabel,
        domain: [`dataMin - ${domainFactor}`, `dataMax + ${domainFactor}`],
      };
    }
  }, [cpi.monthly, mode, thisMonth, thisYear]);

  const budgetChartData = useMemo(() => {
    if (!Object.keys(budget).length) return;

    const thisYearKeyString = thisYear.toString().slice(-2);

    const thisYearData = Object.entries(budget.monthly)
      .filter(([key]) => key.includes(`${thisYearKeyString}M`))
      .map(([, value]) => ({
        value: Object.entries(value).reduce(
          (prev, [key, value]) =>
            prev + value * budget.total[`${thisYearKeyString}TOTAL`][key],
          0
        ),
      }));

    const domainFactor = Math.ceil(
      Math.max(...thisYearData.map((item) => item.value)) -
        Math.min(...thisYearData.map((item) => item.value))
    );

    const startLabel = formatDate(new Date(thisYear, 0, 1));
    const endLabel = formatDate(new Date(thisYear, thisMonth, 0));

    return {
      data: thisYearData,
      startLabel,
      endLabel,
      domain: [`dataMin - ${domainFactor}`, `dataMax + ${domainFactor}`],
    };
  }, [budget, thisMonth, thisYear]);

  const maxDate = chartData.at(-1)?.max_date;
  const minDate = chartData.at(-1)?.min_date;
  const labelValue = chartData.at(-1)?.value;

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" alignItems="center" gap={3}>
          <PageTitle title="Portfolio Health" />
          <SwitchContainer display="flex" alignItems="center" gap={1}>
            <Typography variant="body2">Display mode:</Typography>
            <ModeSwitch
              modes={["%", "#"]}
              currentMode={mode}
              onModeClick={(mode) => setMode(mode)}
              width="72px"
              height="32px"
            />
          </SwitchContainer>
        </Box>
        <Box width="100%" height="35vh" display="flex" gap={3}>
          <ChartContainer width="100%" display="flex">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={chartData}
                margin={{ top: 20, right: 15, bottom: 20 }}
              >
                <XAxis
                  dataKey="date"
                  style={{
                    fontSize: 14,
                  }}
                  tickLine={false}
                  axisLine={false}
                  tickMargin={20}
                  ticks={chartXTicks}
                  tick={{ fill: theme.palette.text.disabled }}
                />
                <YAxis
                  domain={[chartYTicks[0], chartYTicks[2]]}
                  ticks={chartYTicks}
                  dataKey="value"
                  tickLine={false}
                  axisLine={false}
                  interval={0}
                  style={{
                    fontSize: 14,
                  }}
                  tickMargin={24}
                  tick={{ fill: theme.palette.text.disabled }}
                />
                <Tooltip content={<CustomTooltip minDate={minDate} />} />
                <ReferenceLine
                  x={maxDate?.split("-").slice(0, -1).join("-")}
                  stroke={theme.palette.text.disabled}
                />
                <defs>
                  <radialGradient id="colorUv" cx="100%" cy="0" r="100%">
                    <stop
                      offset="30%"
                      stopColor={theme.gradient.lineChart[0]}
                    />
                    <stop
                      offset="60%"
                      stopColor={theme.gradient.lineChart[1]}
                    />
                    <stop
                      offset="100%"
                      stopColor={theme.gradient.lineChart[2]}
                    />
                  </radialGradient>
                </defs>
                <Area
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="value"
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                  dot={false}
                >
                  <LabelList
                    dataKey="min_date"
                    content={
                      <CustomLabel minDate={minDate} labelValue={labelValue} />
                    }
                  />
                </Area>
              </AreaChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Box>
        <Box
          display="grid"
          gridTemplateRows="repeat(3, 1fr)"
          gridTemplateColumns="repeat(3, 1fr)"
          gap={3}
        >
          <PortfolioHealthMetricCard
            title={mode === "%" ? "Occupancy" : "Units Vacant"}
            value={
              mode === "%"
                ? humanizeNumber(occupancy.currentPerc)
                : occupancy.currentUnitsVacant
            }
            caption={`out of ${technicalHealth.units} units`}
            valueAssessment="Moderate"
            graphData={occupancyGraphData}
            unit={mode === "%" ? "%" : "units"}
            onClick={() => navigate("occupancy")}
          />
          <PortfolioHealthMetricCard
            title={
              mode === "%"
                ? `Rent Collected for ${formatDate(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() - 1
                    ),
                    { day: false }
                  )}`
                : "Rent Missing"
            }
            value={
              mode === "%"
                ? humanizeNumber(rcpm.currentRentCollectedPerc)
                : humanizeNumber(rcpm.currentRentMissing).split(",")[0] + "K"
            }
            caption={
              mode === "%"
                ? `out of ${
                    humanizeNumber(rcpm.currentRentDue).split(",")[0]
                  }K CHF Total Rent Gross`
                : `from ${rcpm.tenancies} tenancies`
            }
            valueAssessment="Moderate"
            graphData={rcpmChartData}
            unit={mode === "%" ? "%" : "CHF"}
            onClick={() => navigate("rent-collected")}
          />
          <PortfolioHealthMetricCard
            title={mode === "%" ? "24h Guarantee" : "Average Response Time"}
            value={
              mode === "%"
                ? humanizeNumber(guarantee.L1W?.on_time_response_rate as number)
                : (guarantee.L1W?.avg_res_time as string)
            }
            caption={`in week of ${formatDate(
              guarantee.L1W?.min_date?.toDate() ?? new Date(),
              { year: false }
            )} - ${formatDate(guarantee.L1W?.max_date?.toDate() ?? new Date(), {
              year: false,
            })}`}
            valueAssessment="Moderate"
            graphData={guaranteeChartData}
            unit={mode === "%" ? "%" : ""}
            onClick={() => navigate("24h-guarantee")}
          />
          <PortfolioHealthMetricCard
            title="Rent Increase on Relocation"
            value={
              mode === "%"
                ? humanizeNumber(aiurc.currentRelocationsIncreased)
                : humanizeNumber(aiurc.currentCumulativeRentIncreased)
            }
            caption={
              mode === "%"
                ? `success rate out of ${aiurc.currentRelocationsCount} relocations last month`
                : "total increase since the start of the year"
            }
            valueAssessment="Moderate"
            graphData={aiurcChartData}
            unit={mode === "%" ? "%" : "CHF"}
            onClick={() => navigate("rent-increases")}
          />
          <PortfolioHealthMetricCard
            title={mode === "%" ? "CPI Rent Increases Applied" : "Increased In"}
            value={
              mode === "%"
                ? humanizeNumber(cpi.currentIncreased)
                : humanizeNumber(cpi.currentCumulativeIncreasedIn)
            }
            caption={
              mode === "%"
                ? `from ${formatDate(new Date(thisYear, thisMonth, 1))}`
                : `Total net rent increased this year: ${humanizeNumber(
                    cpi.currentCumulativeIncrease
                  )} CHF`
            }
            valueAssessment="Moderate"
            graphData={cpiChartData}
            unit={mode === "%" ? "%" : "units"}
            onClick={() => navigate("cpi-rent-increases")}
          />
          <PortfolioHealthMetricCard
            title="Budget Used"
            value={
              mode === "%"
                ? humanizeNumber(budget.budgetUsedPerc)
                : humanizeNumber(budget.budgetUsed).split(",")[0] + "K"
            }
            caption={
              mode === "%"
                ? `out of an annual budget of ${
                    humanizeNumber(budget.totalBudget).split(",")[0]
                  }K CHF`
                : `${budget.buildingsOverBudget} building${
                    budget.buildingsOverBudget !== 1 ? "s" : ""
                  } over budget`
            }
            valueAssessment="Moderate"
            graphData={budgetChartData}
            unit={mode === "%" ? "%" : "CHF"}
            onClick={() => navigate("budget")}
          />
          <PortfolioHealthMetricCard
            title={mode === "%" ? "Technical Health" : "Units with issues"}
            value={
              mode === "%"
                ? humanizeNumber(
                    Object.values(technicalHealth.daily).at(-1)
                      ?.technical_health ?? 0
                  )
                : humanizeNumber(
                    Math.ceil(
                      ((100 -
                        (Object.values(technicalHealth.daily).at(-1)
                          ?.technical_health ?? 0)) *
                        technicalHealth.units) /
                        100
                    )
                  )
            }
            caption={`units ${
              mode === "%" ? "without" : "with"
            } issues over the last 3 months`}
            valueAssessment="Moderate"
            graphData={techHealthChartData}
            unit={mode === "%" ? "%" : "units"}
            onClick={() => navigate("technical-health")}
          />
        </Box>
      </Box>
      <Outlet />
    </>
  );
};

const SwitchContainer = styled(Box)`
  padding: 2px 2px 2px 8px;
  border-radius: 12px;
`;

export default PortfolioHealth;
