import React, { useEffect, useState, createContext } from 'react';
import { Timestamp, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Loading from '../components/Loading/Loading';

export const GuaranteeContext = createContext<{
  weeklyData: GuaranteeDataItem[];
  L30D?: GuaranteeDataItem;
  L1M?: GuaranteeDataItem;
  L1W?: GuaranteeDataItem;
}>({
  weeklyData: [],
});

export interface GuaranteeDataItem {
  id: string;
  avg_res_time: string;
  on_time_response_rate: number;
  outside_working_hours_rate: number;
  same_day_res_rate: number;
  week: string;
  weekdays_rate: number;
  min_date?: Timestamp;
  max_date?: Timestamp;
}

type GuaranteeProviderProps = {
  children: React.ReactNode;
};

const GuaranteeProvider: React.FC<GuaranteeProviderProps> = ({ children }) => {
  const [weeklyData, setWeeklyData] = useState<GuaranteeDataItem[]>([]);
  const [l30d, setl30d] = useState<GuaranteeDataItem>();
  const [l1m, setl1m] = useState<GuaranteeDataItem>();
  const [l1w, setl1w] = useState<GuaranteeDataItem>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const collectionRef = collection(db, 'investis-24h-guarantee');
    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
      const dataList: GuaranteeDataItem[] = [];

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (doc.id.includes('CW')) {
          dataList.push({
            id: doc.id,
            ...data,
          } as GuaranteeDataItem);
        } else if (doc.id === 'L30D') {
          setl30d({
            id: doc.id,
            ...data,
          } as GuaranteeDataItem);
        } else if (doc.id === 'L1M') {
          setl1m({
            id: doc.id,
            ...data,
          } as GuaranteeDataItem);
        } else if (doc.id === 'L1W') {
          setl1w({
            id: doc.id,
            ...data,
          } as GuaranteeDataItem);
        }
      });

      setWeeklyData(dataList);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <GuaranteeContext.Provider
      value={{ weeklyData: weeklyData, L1M: l1m, L30D: l30d, L1W: l1w }}
    >
      {isLoading ? <Loading /> : children}
    </GuaranteeContext.Provider>
  );
};

export default GuaranteeProvider;
