import React, { useContext } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { useLocation } from "react-router-dom";
import { Box, styled, Typography } from "@mui/material";
import ModeSwitch from "../ModeSwitch/ModeSwitch";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectMode, setMode } from "../../store/ui.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/AuthContext";

const handleInitials = (userName: string) => {
  if (userName.includes(" ")) {
    const userNameSplit = userName.split(" ");
    return `${userNameSplit[0].charAt(0).toUpperCase()}${userNameSplit[
      userNameSplit.length - 1
    ]
      .charAt(0)
      .toUpperCase()}`;
  } else {
    return userName.substring(0, 2).toUpperCase();
  }
};

const Navbar: React.FC = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentMode = useAppSelector(selectMode);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingBottom="20px"
    >
      <Box display="flex" alignItems="center">
        {location.pathname === "/home" ? (
          <Box display="flex" alignItems="center" gap={2}>
            <Dot />
            <Typography variant="body2" fontWeight="bold">
              Perty Asset Manager OS
            </Typography>
          </Box>
        ) : (
          <Breadcrumbs />
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        {/* <img src={bell} alt="Bell icon" /> */}
        <ModeSwitch
          modes={["light", "dark"]}
          currentMode={currentMode}
          width={72}
          height={36}
          modeLabels={[
            <FontAwesomeIcon icon={faSun} />,
            <FontAwesomeIcon icon={faMoon} />,
          ]}
          onModeClick={(mode) => dispatch(setMode(mode as "light" | "dark"))}
        />
        <NavbarAvatar
          width={40}
          height={40}
          component="span"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {handleInitials(user?.displayName ?? user?.email ?? "")}
        </NavbarAvatar>
      </Box>
      {/* <ToggleSwitch /> */}
    </Box>
  );
};

const NavbarAvatar = styled(Box)`
  flex-shrink: 0;
  margin-left: 12px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: white;
  background-color: ${(props) => props.theme.palette.secondary.main};

  font-weight: 600;
`;

const Dot = styled(Box)`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.text.secondary};
`;

export default Navbar;
