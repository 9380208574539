import React, { useEffect, useState, createContext } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Loading from "../components/Loading/Loading";

interface OpenLegalCases {
  all_cases: number;
  open_cases: number;
  billable_legal_cases: number;
  avg_completion_time: string;
  case_types: {
    [key: string]: number;
  };
}

export const OpenLegalCasesContext = createContext<{
  data: {
    openCases: OpenLegalCases;
    billableCases: BillableCase[];
  };
}>({
  data: {
    openCases: {
      all_cases: 0,
      open_cases: 0,
      billable_legal_cases: 0,
      avg_completion_time: "",
      case_types: {},
    },
    billableCases: [],
  },
});

type OpenLegalCasesProviderProps = {
  children: React.ReactNode;
};

export interface BillableCase {
  date: Date;
  count: number;
}

const OpenLegalCasesProvider: React.FC<OpenLegalCasesProviderProps> = ({
  children,
}) => {
  const [cases, setCases] = useState<OpenLegalCases>({
    all_cases: 0,
    open_cases: 0,
    billable_legal_cases: 0,
    avg_completion_time: "",
    case_types: {},
  });
  const [billable, setBillable] = useState<BillableCase[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "investis-legal-cases", "legal_cases_stats");
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        return;
      }

      setCases(docSnap.data() as OpenLegalCases);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(
        db,
        "investis-legal-cases",
        "billable_legal_cases_count"
      );
      const docSnap = await getDoc(docRef);
      let billableCases: BillableCase[] = [];

      if (docSnap.exists()) {
        Object.keys(docSnap.data()).forEach((key) => {
          billableCases.push({
            date: new Date(key),
            count: docSnap.data()[key].count,
          });
        });
      }
      billableCases = billableCases.sort(
        (a, b) => a.date.getTime() - b.date.getTime()
      );
      setBillable(billableCases);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (cases.all_cases !== 0 && billable.length !== 0) {
      setIsLoading(false);
    }
  }, [cases.all_cases, billable.length]);

  const data = {
    openCases: cases,
    billableCases: billable,
  };

  return (
    <OpenLegalCasesContext.Provider value={{ data }}>
      {isLoading ? <Loading /> : children}
    </OpenLegalCasesContext.Provider>
  );
};

export default OpenLegalCasesProvider;
