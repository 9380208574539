import { FC, useContext, useEffect, useState } from "react";
import Login from "./Login";
import Welcome from "./Welcome";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

type StartWrapperProps = {
  onJump: () => void;
};

const StartWrapper: FC<StartWrapperProps> = ({ onJump }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const [shouldFadeLoginForm, setShouldFadeLoginForm] = useState(true);
  const [shouldFadeWelcomeText, setShouldFadeWelcomeText] = useState(true);
  const [shouldRenderLoginForm, setShouldRenderLoginForm] = useState(false);
  const [shouldRenderWelcomeText, setShouldRenderWelcomeText] = useState(false);
  const [loginHandled, setLoginHandled] = useState(false);

  const handleJump = () => {
    setShouldFadeWelcomeText(true);
    onJump();
    setTimeout(() => navigate("/home"), 3000);
  };

  const handleLogin = () => {
    setLoginHandled(true);
    setShouldFadeLoginForm(true);
    setTimeout(() => {
      setShouldRenderLoginForm(false);
    }, 2000);
  };

  useEffect(() => {
    if (isAuthenticated === false) {
      setShouldRenderLoginForm(true);
      setTimeout(() => {
        setShouldFadeLoginForm(false);
      }, 100);
    } else if (!!isAuthenticated) {
      setTimeout(
        () => {
          setShouldRenderWelcomeText(true);
          setTimeout(() => {
            setShouldFadeWelcomeText(false);
          }, 100);
        },
        loginHandled ? 2000 : 0
      );
    }
  }, [isAuthenticated, loginHandled]);

  return (
    <>
      {shouldRenderLoginForm && (
        <Login fade={shouldFadeLoginForm} onSubmit={handleLogin} />
      )}
      {shouldRenderWelcomeText && (
        <Welcome fade={shouldFadeWelcomeText} onClick={handleJump} />
      )}
    </>
  );
};

export default StartWrapper;
