type FormatNumberArgs = {
  value: number;
  options?: { round?: boolean; format?: "K" | ", " };
};

export const formatNumber = ({
  value,
  options = { round: true, format: "K" },
}: FormatNumberArgs) => {
  const valueString = options.round
    ? Number(value).toFixed(2).toString().split(".")[0]
    : Number(value).toFixed(2).toString();

  if (Math.abs(value) > 999) {
    if (options.format === "K") {
      return `${value
        .toString()
        .slice(0, valueString.length - (options.round ? 3 : 6))}K`;
    } else if (options.format === ", ") {
      return `${value
        .toString()
        .slice(0, valueString.length - (options.round ? 3 : 6))},${valueString
        .toString()
        .slice(valueString.length - (options.round ? 3 : 6))}`;
    }
  }
  return valueString;
};

export const humanizeNumber = (number: number): string => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(number);
};
