import { Marker } from "@googlemaps/markerclusterer";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { FC, useCallback } from "react";

type BuildingMarkerProps = {
  building: {
    key: string;
    address: string;
    position: { lat: number; lng: number };
    color: string;
  };
  setRef: (marker: Marker | null, key: string) => void;
  onClick: () => void;
};

const BuildingMarker: FC<BuildingMarkerProps> = ({
  building,
  setRef,
  onClick,
}) => {
  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) =>
      setRef(marker, building.key),
    [building.key, setRef]
  );

  return (
    <AdvancedMarker
      position={building.position}
      ref={ref}
      onClick={onClick}
      title={building.address}
    >
      <div
        style={{
          width: 16,
          height: 16,
          position: "absolute",
          top: 0,
          left: 0,
          border: `5px solid ${building.color}`,
          borderRadius: "50%",
          transform: "translate(-50%, -50%)",
        }}
      ></div>
    </AdvancedMarker>
  );
};

export default BuildingMarker;
