import React, { useCallback, useMemo, useState } from "react";
import SidebarItem, { SidebarItemProps } from "./SidebarItem/SidebarItem";
import pertyLogo from "../../assets/images/perty_logo.png";
import { useLocation } from "react-router-dom";
import {
  faAnglesLeft,
  faAnglesRight,
  faArrowRightFromBracket,
  faChartLine,
  faClockRotateLeft,
  faFileText,
  faHouseChimneyMedical,
  faPlugCircleCheck,
  faScaleBalanced,
} from "@fortawesome/free-solid-svg-icons";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { Box, styled } from "@mui/material";

const sidebarTopItems: (Omit<SidebarItemProps, "active"> & {
  active?: boolean;
  customRoute?: string;
})[] = [
  {
    id: "home",
    name: "Home",
    icon: faHouseChimneyMedical,
  },
  {
    id: "portfolio-health",
    name: "Portfolio Health",
    icon: faChartLine,
  },
  {
    id: "24h-guarantee",
    name: "24h",
    icon: faClockRotateLeft,
    customRoute: "portfolio-health/24h-guarantee",
  },
  {
    id: "consumption",
    name: "Consumption",
    icon: faPlugCircleCheck,
  },
  {
    id: "legal-cases",
    name: "Legal Cases",
    icon: faScaleBalanced,
  },
  { id: "reports", name: "Reports", icon: faFileText },
];

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const selectedSidebarItem = useMemo(() => {
    return (
      sidebarTopItems.find((item) =>
        location.pathname.split("/").at(-1)?.includes(item.id)
      ) ??
      sidebarTopItems.find((item) =>
        location.pathname.split("/").includes(item.id)
      )
    );
  }, [location.pathname]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleSidebarExpansion = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const sidebarBottomItems: SidebarItemProps[] = useMemo(
    () => [
      {
        id: "collapse",
        name: "Collapse",
        icon: isExpanded ? faAnglesLeft : faAnglesRight,
        active: false,
        onClick: handleSidebarExpansion,
      },
      {
        id: "logout",
        name: "Log out",
        icon: faArrowRightFromBracket,
        active: false,
        onClick: handleLogout,
      },
    ],
    [handleSidebarExpansion, isExpanded]
  );

  return (
    <SidebarRoot
      width={isExpanded ? 240 : 64}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <SidebarItem
          key="sidebarLogo"
          src={pertyLogo}
          active={false}
          id="home"
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {sidebarTopItems.map((item, index) => (
          <SidebarItem
            key={"sidebarTopItem" + index}
            id={item.id}
            name={item.name}
            icon={item.icon}
            src={item.src}
            active={selectedSidebarItem === item}
            customRoute={item.customRoute}
          />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {sidebarBottomItems.map((item, index) => (
          <SidebarItem
            key={"sidebarBottomItem" + index}
            id={item.id}
            name={item.name}
            icon={item.icon}
            active={selectedSidebarItem === item}
            onClick={item.onClick}
            applyActiveColor={true}
          />
        ))}
      </Box>
    </SidebarRoot>
  );
};

const SidebarRoot = styled(Box)`
  padding: 32px 0;
  overflow: hidden;
  transition: width 0.5s ease;
`;

export default Sidebar;
