/**
 * Generates an array of numbers from `start` to `stop`, incrementing by `step`.
 * The `step` parameter is optional and defaults to 1.
 * 
 * @param start - The start of the range.
 * @param stop - The end of the range, inclusive.
 * @param step - The step between each number in the range. Defaults to 1.
 * @returns An array of numbers from `start` to `stop` with the specified step.
 */
export function generateRange(start: number, stop: number, step: number = 1): number[] {
    const range: number[] = []

    if (step <= 0) {
        step = 1
    }

    for (let i = start; i <= stop; i += step) {
        range.push(i);
    }

    return range;
}