import { Navigate, Outlet } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { Box, styled } from '@mui/material';
import { PropsWithChildren, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectFade, setFade } from '../../store/ui.slice';
import PortfolioHealthProvider from '../../context/PortfolioHealthContext';
import OccupancyProvider from '../../context/OccupancyContext';
import RentCollectedProvider from '../../context/RentCollectedContext';
import RentIncreasesProvider from '../../context/RentIncreasesContext';
import CpiIncreasesProvider from '../../context/CpiIncreasesContext';
import BudgetProvider from '../../context/BudgetContext';
import TechnicalHealthProvider from '../../context/TechnicalHealthContext';
import GuaranteeProvider from '../../context/GuaranteeContext';
import BuildingsProvider from '../../context/BuildingsContext';
import SecretsProvider from '../../context/SecretsContext';
import RelocationsProvider from '../../context/RelocationsContext';

const PrivateRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useContext(AuthContext);
  const fade = useAppSelector(selectFade);

  useEffect(() => {
    if (fade) {
      dispatch(setFade(false));
    }
  }, [dispatch, fade]);

  if (isAuthenticated === false) {
    return <Navigate to="/" />;
  }
  return (
    <PortfolioHealthProvider>
      <OccupancyProvider>
        <RentCollectedProvider>
          <GuaranteeProvider>
            <RentIncreasesProvider>
              <RelocationsProvider>
                <CpiIncreasesProvider>
                  <BudgetProvider>
                    <BuildingsProvider>
                      <SecretsProvider>
                        <TechnicalHealthProvider>
                          <Fade
                            display="flex"
                            width="100vw"
                            height="100vh"
                            enabled={fade}
                          >
                            <Sidebar />
                            <ContentContainer width="100vw" height="100vh">
                              <Content
                                width="100%"
                                height="100%"
                                display="flex"
                                flexDirection="column"
                              >
                                <Navbar />
                                {children}
                                <Outlet />
                              </Content>
                            </ContentContainer>
                          </Fade>
                        </TechnicalHealthProvider>
                      </SecretsProvider>
                    </BuildingsProvider>
                  </BudgetProvider>
                </CpiIncreasesProvider>
              </RelocationsProvider>
            </RentIncreasesProvider>
          </GuaranteeProvider>
        </RentCollectedProvider>
      </OccupancyProvider>
    </PortfolioHealthProvider>
  );
};

const Fade = styled(Box, { shouldForwardProp: (prop) => prop !== 'enabled' })<{
  enabled: boolean;
}>`
  opacity: ${(props) => (props.enabled ? 0 : 1)};
  transition: all 1s ease;
`;

const ContentContainer = styled(Box)`
  border-radius: 32px 0 0 32px;
  overflow-x: hidden;
`;

const Content = styled(Box)`
  padding: ${(props) => props.theme.spacing(4)};
  background: transparent;
  overflow-x: hidden;
  overflow-y: auto;
`;

export default PrivateRoute;
