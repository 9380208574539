import { Box, styled } from "@mui/material";

const ChartContainer = styled(Box)`
  padding: 20px;
  border-radius: 12px;
  background: ${({ theme }) => theme.cardBackground};
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
`;

export default ChartContainer;
