import { useAppSelector } from "./store";
import { selectMode } from "./store/ui.slice";
import { darkTheme, lightTheme } from "./theme";

export const useTheme = () => {
  const mode = useAppSelector(selectMode);
  const theme = mode === "light" ? lightTheme : darkTheme;

  return theme;
};
