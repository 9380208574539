import React from 'react'
import './NotFound.css'

const NotFound: React.FC = () => (
    <div className="NotFound">
        <h2>Troubles in paradise</h2>
        <p>The page you are looking for does not exist</p>
    </div>
);

export default NotFound;