import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Consumption from './components/Consumption/Consumption';
import Home from './components/Home/Home';
import NotFound from './components/NotFound/NotFound';

import Guarantee from './components/Guarantee/Guarantee';
import LegalCases from './components/LegalCases/LegalCases';

import OpenLegalCasesProvider from './context/OpenLegalCasesContext';
import CasesStatusProvider from './context/CaseStatusContext';
import ConsumptionProvider from './context/ConsumptionContext';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { User } from 'firebase/auth';
import PortfolioHealth from './components/PortfolioHealth/PortfolioHealth';
import Reports from './components/Reports/Reports';
import { Box, CssBaseline, ThemeProvider, styled } from '@mui/material';
import OccupancyDetails from './components/PortfolioHealth/OccupancyDetails';
import RentCollectedDetails from './components/PortfolioHealth/RentCollectedDetails';
import RentIncreaseDetails from './components/PortfolioHealth/RentIncreaseDetails';
import CpiIncreaseDetails from './components/PortfolioHealth/CpiIncreaseDetails';
import BudgetDetails from './components/PortfolioHealth/BudgetDetails/BudgetDetails';
import TechnicalHealthDetails from './components/PortfolioHealth/TechnicalHealthDetails';
import { useTheme } from './useTheme';
import Start from './components/Login/Start';
import { AuthContext } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

const App: React.FC = () => {
  const { user, userAcceptedDisclaimer } = useContext(AuthContext);
  const theme = useTheme();

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MainContainer width="100vw" height="100vh" display="flex">
            <Routes>
              <Route path="/">
                <Route index element={<Start />} />
                <Route path="/login" element={<Navigate to="/" />} />

                {/* When user first logs in, we should serve a licence agreement */}
                {/* there’s no guarantee that the data here are gonna be correct, 
              this is not an investment advice etc etc */}

                {/* // Catch-all route for undefined paths */}
                <Route path="*" element={<NotFound />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/home" element={<Home />} />
                  <Route
                    path="/consumption"
                    element={
                      <ConsumptionProvider>
                        <Consumption
                          user={user as User}
                          disclaimerConsent={userAcceptedDisclaimer}
                        />
                      </ConsumptionProvider>
                    }
                  />
                  <Route
                    path="/legal-cases"
                    element={
                      <OpenLegalCasesProvider>
                        <CasesStatusProvider>
                          <LegalCases />
                        </CasesStatusProvider>
                      </OpenLegalCasesProvider>
                    }
                  />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/portfolio-health">
                    <Route index element={<PortfolioHealth />} />
                    <Route path="occupancy" element={<OccupancyDetails />} />
                    <Route
                      path="rent-collected"
                      element={<RentCollectedDetails />}
                    />
                    <Route
                      path="rent-increases"
                      element={<RentIncreaseDetails />}
                    />
                    <Route
                      path="cpi-rent-increases"
                      element={<CpiIncreaseDetails />}
                    />
                    <Route path="budget" element={<BudgetDetails />} />
                    <Route path="24h-guarantee" element={<Guarantee />} />
                    <Route
                      path="technical-health"
                      element={<TechnicalHealthDetails />}
                    />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </MainContainer>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

const MainContainer = styled(Box)`
  background: ${({ theme }) => theme.background};
`;

export default App;
