import { collection, onSnapshot } from 'firebase/firestore';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import Loading from '../components/Loading/Loading';

export interface BudgetRecommendation {
  reallocate_comment: string;
  reallocate_from: string;
  reallocate_percentage: number;
  recommendation_type: 'reallocate' | 'leave_intact';
}

export type BudgetData = {
  monthly: {
    [month: string]: {
      [buildingId: string]: number;
    };
  };
  total: {
    [year: string]: {
      [buildingId: string]: number;
    };
  };
  totalBudget: number;
  budgetUsedPerc: number;
  budgetUsed: number;
  buildingsOverBudget: number;
  recommendations: {
    [buildingId: string]: BudgetRecommendation;
  };
};

const initialValue: BudgetData = {
  monthly: {},
  total: {},
  totalBudget: 0,
  budgetUsedPerc: 0,
  budgetUsed: 0,
  buildingsOverBudget: 0,
  recommendations: {},
};

export const BudgetContext = createContext<BudgetData>(initialValue);

type BudgetProviderProps = {
  children: ReactNode;
};

const BudgetProvider: React.FC<BudgetProviderProps> = ({ children }) => {
  const [data, setData] = useState<BudgetData>(initialValue);
  const [isLoadingBudget, setIsLoadingBudget] = useState(true);
  const [isLoadingRecommendations, setIsLoadingRecommendations] =
    useState(true);
  const isLoading = isLoadingBudget || isLoadingRecommendations;

  useEffect(() => {
    const budgetRef = collection(db, 'investis-budget');
    const unsubscribeBudget = onSnapshot(budgetRef, (snapshot) => {
      if (!snapshot.empty) {
        const docs = snapshot.docs.map((doc) => [doc.id, doc.data()]);

        const monthly = Object.fromEntries(
          docs.filter(([id]) => id.includes('M'))
        ) as BudgetData['monthly'];

        const total = Object.fromEntries(
          docs.filter(([id]) => id.includes('TOTAL'))
        ) as BudgetData['total'];

        const lastMonthKey = Object.keys(monthly)
          .filter((key) => !key.includes('PROJECTION'))
          .sort()
          .pop()!;

        const thisYearKey = Object.keys(total).sort().pop()!;

        const totalBudget = Object.values(total[thisYearKey]).reduce(
          (acc, value) => acc + value,
          0
        );

        const budgetUsed = Object.entries(monthly[lastMonthKey]).reduce(
          (acc, [key, value]) => acc + value * total[thisYearKey][key],
          0
        );

        const budgetUsedPerc = (budgetUsed / totalBudget) * 100;

        const buildingsOverBudget = Object.values(monthly[lastMonthKey]).filter(
          (value) => value > 1
        ).length;

        setData((data) => ({
          ...data,
          monthly,
          total,
          totalBudget,
          budgetUsedPerc,
          budgetUsed,
          buildingsOverBudget,
        }));
        setIsLoadingBudget(false);
      }
    });

    const recommendationsRef = collection(db, 'investis-budget-reco');
    const unsubscribeRecommendations = onSnapshot(
      recommendationsRef,
      (snapshot) => {
        const recommendations = Object.fromEntries(
          snapshot.docs.map((doc) => [doc.id, doc.data()])
        ) as BudgetData['recommendations'];

        setData((data) => ({
          ...data,
          recommendations,
        }));
        setIsLoadingRecommendations(false);
      }
    );

    return () => {
      unsubscribeBudget();
      unsubscribeRecommendations();
    };
  }, []);

  return (
    <BudgetContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </BudgetContext.Provider>
  );
};

export default BudgetProvider;
