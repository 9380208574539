import { FC, ReactNode } from "react";
import {
  ResponsiveContainer,
  AreaChart as ReAreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import SimpleChartTooltip from "../SimpleChartTooltip";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";
import { formatNumber } from "../../../helpers/NumberHelpers";
import ChartContainer from "../ChartContainer";
import { useTheme } from "@mui/material/styles";

type AreaChartValue = {
  value: string | number;
  label: string;
};

type AreaChartProps = {
  values: AreaChartValue[];
  domain: [number, number] | [string, string];
  width: number | string;
  height: number | string;
  tooltipTitle?: string;
  unit?: string;
  hideDots?: boolean;
  showAxisUnit?: boolean;
  slots?: {
    root?: ReactNode;
    area?: ReactNode;
  };
  props?: CategoricalChartProps;
  tooltipValueFormatter?: (value: number) => string;
};

const AreaChart: FC<AreaChartProps> = ({
  values,
  domain,
  width,
  height,
  tooltipTitle,
  unit,
  showAxisUnit = false,
  hideDots = false,
  slots,
  props,
  tooltipValueFormatter,
}) => {
  const theme = useTheme();
  return (
    <ChartContainer width={width} height={height}>
      <ResponsiveContainer width="100%" height="100%">
        <ReAreaChart
          data={values}
          margin={{ top: 20, right: 15, bottom: 20, left: 20 }}
          {...props}
        >
          <XAxis
            dataKey="label"
            style={{
              fontSize: 14,
            }}
            tickLine={false}
            axisLine={false}
            tickMargin={20}
            tick={{ fill: theme.palette.text.disabled }}
            interval={
              values.length > 24 ? Math.round(values.length / 12) : undefined
            }
          />
          <YAxis
            domain={domain}
            interval={0}
            ticks={[
              domain[0],
              ((domain[0] as number) + (domain[1] as number)) / 2,
              domain[1],
            ]}
            dataKey="value"
            tickFormatter={(value) =>
              `${formatNumber({ value: value as number })}${
                unit === "%" ? "" : " "
              }${showAxisUnit ? unit ?? "" : ""}`
            }
            tickLine={false}
            axisLine={false}
            style={{
              fontSize: 14,
            }}
            tickMargin={20}
            tick={{ fill: theme.palette.text.disabled }}
          />
          <defs>
            <radialGradient id="colorUv" cx="100%" cy="0" r="100%">
              <stop offset="30%" stopColor={theme.gradient.lineChart[0]} />
              <stop offset="60%" stopColor={theme.gradient.lineChart[1]} />
              <stop offset="100%" stopColor={theme.gradient.lineChart[2]} />
            </radialGradient>
          </defs>
          <Area
            type="linear"
            isAnimationActive={false}
            dataKey="value"
            stroke={theme.palette.primary.main}
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#colorUv)"
            dot={false}
          >
            {slots?.area}
          </Area>
          {tooltipTitle && (
            <Tooltip
              content={
                <SimpleChartTooltip
                  title={tooltipTitle}
                  valueFormatter={tooltipValueFormatter}
                  unit={unit}
                />
              }
            />
          )}
          {slots?.root}
        </ReAreaChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default AreaChart;
