import { GridColDef } from "@mui/x-data-grid";

export const columnsAggregated: GridColDef[] = [
        { 
            field: 'year',
            headerName: 'Year',
            flex: 0.5,
            editable: false,
            type: 'string',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center'
        },
        { 
            field: 'electricity',
            headerName: 'Electricity YoY [%]',
            flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center'
        },
        { 
            field: 'gas',
            headerName: 'Gas YoY [%]',
            flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center'
        },
        { 
            field: 'water',
            headerName: 'Water YoY [%]',
            flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center'
        },
        { 
            field: 'heating',
            headerName: 'Heating YoY [%]',
            flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center'
        },
        { 
            field: 'mazout',
            headerName: 'Mazout YoY [%]',
            flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center'
        },
    ];

type CustomColDef = GridColDef & {
    variants?: string[]
}
    
export const columnsNotAggregated: CustomColDef[] = [
        { 
            field: 'year',
            headerName: 'Year',
            // flex: 0.5,
            editable: false,
            type: 'string',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            variants: ['electricity', 'gas', 'heating', 'water', 'mazout']
        },
        { 
            field: 'building_id',
            headerName: 'Building ID',
            // flex: 0.5,
            editable: false,
            type: 'string',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['electricity', 'gas', 'heating', 'water', 'mazout']
        },
        { 
            field: 'building_address',
            headerName: 'Address',
            // flex: 1,
            editable: false,
            type: 'string',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 300,
            variants: ['electricity', 'gas', 'heating', 'water', 'mazout']
        },
        { 
            field: 'building_commune',
            headerName: 'Commune',
            // flex: 1,
            editable: false,
            type: 'string',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 300,
            variants: ['electricity', 'gas', 'heating', 'water', 'mazout']
        },
        { 
            field: 'building_sre',
            headerName: 'SRE',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['electricity', 'gas', 'heating', 'water', 'mazout']
        },
        { 
            field: 'building_energy_agent',
            headerName: 'Main Energy Agent',
            // flex: 1,
            editable: false,
            type: 'string',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 300,
            variants: ['electricity', 'gas', 'heating', 'water', 'mazout']
        },
        { 
            field: 'electricity_total',
            headerName: 'Electricity [kWh]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['electricity']
        },
        { 
            field: 'electricity_produced',
            headerName: 'Elec. Produced [kWh]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['electricity']
        },
        { 
            field: 'gas_total',
            headerName: 'Gas [kWh]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['gas']
        },
        { 
            field: 'water_total',
            headerName: 'Water [m3]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['water']
        },
        { 
            field: 'heating_total',
            headerName: 'Heating [kWh]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['heating']
        },
        { 
            field: 'mazout_total',
            headerName: 'Mazout [L]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['heating', 'mazout']
        },
        { 
            field: 'electricity_m2',
            headerName: 'Electricity/m2 [kWh/m2]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['electricity']
        },
        { 
            field: 'gas_m2',
            headerName: 'Gas/m2 [kWh/m2]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['gas']
        },
        { 
            field: 'water_m2',
            headerName: 'Water/m2 [m3/m2]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['water']
        },
        { 
            field: 'heating_m2',
            headerName: 'Heating/m2 [kWh/m2]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['heating']
        },
        { 
            field: 'mazout_m2',
            headerName: 'Mazout/m2 [L/m2]',
            // flex: 1,
            editable: false,
            type: 'number',
            sortable: true,
            headerClassName: 'data-grid--header',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            variants: ['heating', 'mazout']
        },
    ];