import { collection, onSnapshot, Timestamp } from 'firebase/firestore';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import Loading from '../components/Loading/Loading';

type Relocation = {
  address: string;
  move_out_date: Timestamp;
  new_rent_net: 180;
  notes: string;
  old_rent_net: 180;
  remarks: string;
  status: string;
  url: string;
};

type RelocationsData = {
  [id: string]: Relocation;
};

export const RelocationsContext = createContext<RelocationsData>(
  {} as RelocationsData
);

type RelocationsProviderProps = {
  children: ReactNode;
};

const RelocationsProvider: FC<RelocationsProviderProps> = ({ children }) => {
  const [data, setData] = useState<RelocationsData>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const collectionRef = collection(db, 'investis-upcoming-reloc');
    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
      if (snapshot.empty) {
        return;
      }

      const relocations = Object.fromEntries(
        snapshot.docs.map((doc) => [doc.id, doc.data() as Relocation])
      );
      setData(relocations);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <RelocationsContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </RelocationsContext.Provider>
  );
};

export default RelocationsProvider;
