import { Box } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import Hyperspace, { HyperspaceHandle } from "./Hyperspace";
import StartWrapper from "./StartWrapper";
import { useAppDispatch } from "../../store";
import { setFade } from "../../store/ui.slice";

const Start: FC = () => {
  const dispatch = useAppDispatch();
  const hyperspaceRef = useRef<HyperspaceHandle>(null);

  const handleJump = () => {
    if (!hyperspaceRef.current) return;
    hyperspaceRef.current.jump();
  };

  useEffect(() => {
    dispatch(setFade(true));
  }, [dispatch]);

  return (
    <Box width="100vw" height="100vh" sx={{ overflow: "hidden" }}>
      <Hyperspace ref={hyperspaceRef} />
      <StartWrapper onJump={handleJump} />
    </Box>
  );
};

export default Start;
