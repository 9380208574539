import { BudgetRecommendation } from '../../../context/BudgetContext';
import { FC, useContext, useMemo, useState } from 'react';
import { BuildingsContext } from '../../../context/BuildingsContext';
import {
  Box,
  Divider,
  IconButton,
  Modal,
  styled,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from '../../../store';
import { selectMode } from '../../../store/ui.slice';

type BudgetRecommendationsModalProps = {
  recommendations: { [buildingId: string]: BudgetRecommendation };
  open: boolean;
  onClose: () => void;
};

const BudgetRecommendationsModal: FC<BudgetRecommendationsModalProps> = ({
  recommendations,
  open,
  onClose,
}) => {
  const theme = useTheme();
  const buildings = useContext(BuildingsContext);
  const mode = useAppSelector(selectMode);
  const [selectedBuilding, setSelectedBuilding] = useState<string>(
    Object.entries(recommendations).find(
      ([, value]) => value.recommendation_type === 'reallocate'
    )?.[0] ?? Object.keys(buildings)[0]
  );

  const buildingsAddressMap = Object.fromEntries(
    Object.entries(buildings).map(([id, building]) => [
      id,
      building.address.split(', ').slice(0, -1).join(', '),
    ])
  );

  const recommendationComment = useMemo(() => {
    let comment = recommendations[selectedBuilding].reallocate_comment;
    const buildingIds = comment.match(/([0-9]{6})/g);
    if (!buildingIds) {
      return comment;
    }
    for (const id of buildingIds) {
      comment = comment.replace(id, `${buildingsAddressMap[id]}`);
    }
    return comment;
  }, [buildingsAddressMap, recommendations, selectedBuilding]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        '*': { outline: 'none' },
        '.MuiBackdrop-root': { backgroundColor: 'transparent' },
      }}
    >
      <BudgetRecommendationModalRoot
        display="flex"
        flexDirection="column"
        gap={3}
        mode={mode}
      >
        <CloseButton onClick={onClose}>×</CloseButton>
        <Box display="flex" alignItems="center" gap={1}>
          <FontAwesomeIcon
            icon={faMagicWandSparkles}
            color={theme.palette.text.primary}
            fontSize={20}
          />
          <Typography variant="h6" fontWeight="bold">
            2025 AI Recommendations
          </Typography>
        </Box>
        <Box display="flex" gap={2}>
          <BuildingsList>
            {Object.entries(recommendations)
              .sort((a, b) => {
                if (
                  a[1].recommendation_type === 'reallocate' &&
                  b[1].recommendation_type !== 'reallocate'
                ) {
                  return -1;
                }
                return 0;
              })
              .map(([buildingId]) => {
                const building = buildings[buildingId];
                if (!building) return null;
                return (
                  <ListItem
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    key={buildingId}
                    selected={buildingId === selectedBuilding}
                    onClick={() => setSelectedBuilding(buildingId)}
                  >
                    <Typography variant="body2">
                      {building.address.split(', ').slice(0, 1).join(', ')}
                    </Typography>
                    {recommendations[buildingId].recommendation_type ===
                      'reallocate' && (
                      <FontAwesomeIcon
                        icon={faMagicWandSparkles}
                        color={
                          buildingId === selectedBuilding
                            ? theme.palette.secondary.light
                            : theme.palette.text.disabled
                        }
                      />
                    )}
                  </ListItem>
                );
              })}
          </BuildingsList>
          <Divider orientation="vertical" />
          <Recommendations display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5">
              {buildings[selectedBuilding].address
                .split(', ')
                .slice(0, -1)
                .join(', ')}
            </Typography>
            <Typography variant="body2">{recommendationComment}</Typography>
            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
              Recommendations:{' '}
              <b>
                {recommendations[selectedBuilding].recommendation_type.replace(
                  '_',
                  ' '
                )}{' '}
                {recommendations[selectedBuilding].recommendation_type ===
                  'reallocate' &&
                  `${
                    recommendations[selectedBuilding].reallocate_percentage
                  }% from ${
                    buildingsAddressMap[
                      recommendations[selectedBuilding].reallocate_from
                    ]
                  }`}
              </b>
            </Typography>
          </Recommendations>
        </Box>
      </BudgetRecommendationModalRoot>
    </Modal>
  );
};

const BudgetRecommendationModalRoot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'mode',
})<{ mode: 'light' | 'dark' }>(
  ({ theme, mode }) => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  height: 70vh;
  background: linear-gradient(140deg, rgba(255, 255, 255, ${
    mode === 'light' ? 0.7 : 0.1
  }) 40%, ${theme.palette.primary.main} 180%);
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.1);
  padding: ${theme.spacing(3)};
  border-radius: 10px;
  backdrop-filter: blur(40px);
`
);

const BuildingsList = styled(Box)`
  width: 30%;
  overflow-y: auto;
  flex-shrink: 0;
`;

const ListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected?: boolean }>(
  ({ theme, selected }) => `
  padding: 6px;
  cursor: pointer;
  border: ${`1px solid ${
    selected ? theme.palette.secondary.light : 'transparent'
  }`};
  border-radius: 8px;
  &:hover {
    background-color: ${theme.palette.secondary.light.replace('1)', '0.3)')};
  }
`
);

const Recommendations = styled(Box)`
  flex-grow: 0;
  padding: 24px 16px;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 32px;
  width: 32px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export default BudgetRecommendationsModal;
