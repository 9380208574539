import React, { useEffect, useState, createContext } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import {
  Building,
  BuildingHeatingItem,
  ConsumptionItem,
} from "../components/Consumption/Consumption";
import Loading from "../components/Loading/Loading";

export const ConsumptionContext = createContext<{
  data: {
    buildings: Building[];
    consumption: ConsumptionItem[];
    heating: BuildingHeatingItem[];
    currentConsumption: currentConsumptionItem[];
  };
}>({
  data: {
    buildings: [],
    consumption: [],
    heating: [],
    currentConsumption: [],
  },
});

interface currentConsumptionItem {
  name: string;
  value: number;
}

type ConsumptionProviderProps = {
  children: React.ReactNode;
};

const ConsumptionProvider: React.FC<ConsumptionProviderProps> = ({
  children,
}) => {
  const [buildingsList, setBuildingsList] = useState<Building[]>([]);
  const [consumptionData, setConsumptionData] = useState<ConsumptionItem[]>([]);
  const [heatingParams, setHeatingParams] = useState<BuildingHeatingItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const collectionRef = collection(db, "buildings_geocoded");
      const snapshot = await getDocs(collectionRef);
      const dataList: Building[] = [];

      snapshot.docs.forEach((doc) => {
        let data = doc.data();
        if (doc.id.length === 6) {
          let addressSplit = data.address.split(", ");
          dataList.push({
            id: doc.id,
            address: addressSplit[0],
            fullAddress: data.address,
            city: addressSplit[1],
            postcode: addressSplit[2],
            canton: data.canton,
          } as Building);
        }
      });

      setBuildingsList(dataList);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const startYear = 2017;
      const currentYear = new Date().getFullYear();

      const promises = buildingsList.flatMap((building) =>
        Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
          const year = startYear + index;
          const docRef = doc(
            db,
            "investis-consumption",
            building.id.toString(),
            year.toString(),
            "consumption"
          );

          return getDoc(docRef).then((snapshot) => {
            const docData = snapshot.data();
            return {
              building_id: building.id.toString(),
              year,
              electricity_total: docData?.electricity_total ?? 0,
              electricity_produced: docData?.electricity_produced ?? 0,
              gas_total: docData?.gas_total ?? 0,
              water_total: docData?.water_total ?? 0,
              heating_total: docData?.heating_total ?? 0,
              mazout_total: docData?.mazout_total ?? 0,
            } as ConsumptionItem;
          });
        })
      );
      const fetchedRecords = (await Promise.all(promises)).filter(
        (record) => record !== null
      );
      setConsumptionData(fetchedRecords);
    };
    fetchData();
  }, [buildingsList]);

  useEffect(() => {
    const fetchData = async () => {
      const collectionRef = collection(db, "investis-buildings-esg");
      const snapshot = await getDocs(collectionRef);
      const dataList: BuildingHeatingItem[] = [];

      snapshot.docs.forEach((doc) => {
        let data = doc.data();
        if (doc.id.length === 6) {
          dataList.push({
            building_id: doc.id,
            commune: data.commune,
            energy_agent: data.energy_agent,
            sre: data.sre,
          } as BuildingHeatingItem);
        }
      });

      setHeatingParams(dataList);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (
      consumptionData.length > 0 &&
      heatingParams.length > 0 &&
      buildingsList.length > 0
    ) {
      setIsLoading(false);
    }
  }, [buildingsList.length, consumptionData.length, heatingParams.length]);

  const currentConsumptionFiltered = consumptionData.filter(
    (item) => item.year === new Date().getFullYear()
  );
  let currentConsumption = { electricity: 0, gas: 0, heating: 0 };
  currentConsumptionFiltered.forEach((item) => {
    currentConsumption.electricity =
      currentConsumption.electricity + item.electricity_total;
    currentConsumption.gas = currentConsumption.gas + item.gas_total;
    currentConsumption.heating =
      currentConsumption.heating + item.heating_total;
  });

  let outputConsumption = [
    { name: "Electricity", value: currentConsumption.electricity },
    { name: "Gas", value: currentConsumption.gas },
    { name: "Heating", value: currentConsumption.heating },
  ];

  const data = {
    buildings: buildingsList,
    consumption: consumptionData,
    heating: heatingParams,
    currentConsumption: outputConsumption,
  };

  return (
    <ConsumptionContext.Provider value={{ data }}>
      {isLoading ? <Loading /> : children}
    </ConsumptionContext.Provider>
  );
};

export default ConsumptionProvider;
