import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faDroplet,
  faFire,
  faFireFlameSimple,
  faGasPump,
} from "@fortawesome/free-solid-svg-icons";
import { Box, styled, Typography } from "@mui/material";

interface UtilityCardProps {
  title: string;
  value: string | number | undefined;
  unit?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  active?: boolean;
  source?: string;
}

const UtilityCard: React.FC<UtilityCardProps> = ({
  title,
  value,
  unit,
  onClick,
  active = false,
  source,
}) => {
  return (
    <UtilityCardRoot active={active} onClick={onClick}>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" gap={1}>
          {title === "Electricity" ? (
            <FontAwesomeIcon icon={faBolt} color="#FFD029" />
          ) : title === "Gas" ? (
            <FontAwesomeIcon icon={faFireFlameSimple} color="#3DC092" />
          ) : title === "Water" ? (
            <FontAwesomeIcon icon={faDroplet} color="#429AF2" />
          ) : title === "Heating" ? (
            <FontAwesomeIcon icon={faFire} color="#FF837B" />
          ) : title === "Mazout" ? (
            <FontAwesomeIcon icon={faGasPump} color="#364152" />
          ) : (
            ""
          )}
          {title}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={1.5} marginTop="20px">
        <Box display="flex">
          {value ? (
            (value as number) > 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5Z"
                  fill="#F9D9D9"
                />
                <path d="M12 9L9 6L6 9" fill="#F9D9D9" />
                <path
                  d="M12 9L9 6M9 6L6 9M9 6V12M1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9Z"
                  stroke="#942F2F"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5Z"
                  fill="#D9F9E6"
                />
                <path d="M12 9L9 12L6 9" fill="#D9F9E6" />
                <path
                  d="M12 9L9 12M9 12L6 9M9 12V6M1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9Z"
                  stroke="#2F9461"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )
          ) : (
            ""
          )}
        </Box>
        <Box>
          <Typography variant="h5">
            {value
              ? (value as number) > 100
                ? (value as number).toFixed(2)
                : (value as number).toFixed(2)
              : "0"}
            <span className="unit">{unit}</span>
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2">vs same time last year</Typography>
      {source ? (
        <Typography variant="caption" marginTop="auto">
          <i>source: {source}</i>
        </Typography>
      ) : (
        ""
      )}
    </UtilityCardRoot>
  );
};

const UtilityCardRoot = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(
  ({ theme, active }) => `
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    border: ${
      active
        ? `2px solid ${theme.palette.primary.light}`
        : "2px solid transparent"
    };
    border-radius: 12px;
    background: ${theme.cardBackground};
    box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
    border: 2px solid ${theme.palette.primary.light};
    filter: brightness(1.05);
    }
`
);

export default UtilityCard;
