import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevronRight.svg";
import { Box, styled } from "@mui/material";

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const toTitleCase = (str: string): string => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  function serveBreadcrumbs(): Array<string> {
    const breadcrumbs: Array<string> = currentPath.split("/");
    const filteredBreadcrumbs = breadcrumbs.filter(
      (breadcrumb) => breadcrumb !== ""
    );
    const titleCasedBreadcrumbs = filteredBreadcrumbs.map((breadcrumb) =>
      toTitleCase(breadcrumb.replaceAll("-", " ")).replace("Cpi", "CPI")
    );

    return ["Home", ...titleCasedBreadcrumbs];
  }

  const breadcrumbs = serveBreadcrumbs();

  const handleBreadcrumbClick = (breadcrumb: string) => {
    navigate(`/${breadcrumb.toLowerCase().replaceAll(" ", "-")}`);
  };

  return (
    <Box display="flex" alignItems="center">
      {breadcrumbs.map((breadcrumb, index) => (
        <BreadcrumbsItem
          display="flex"
          alignItems="center"
          active={index + 1 === breadcrumbs.length}
          key={index}
          onClick={(e) => handleBreadcrumbClick(breadcrumb)}
        >
          {breadcrumb} {index + 1 < breadcrumbs.length ? <ChevronIcon /> : ""}
        </BreadcrumbsItem>
      ))}
    </Box>
  );
};

const BreadcrumbsItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(
  ({ theme, active }) => `
  color: ${
    active ? theme.palette.secondary.main : theme.palette.text.secondary
  };
  font-size: 14px;
  font-style: normal;
  font-weight: ${active ? 600 : 500};
  line-height: 20px;

  &:not(:last-of-type) {
    cursor: pointer;
  }
`
);

const ChevronIcon = styled(ChevronRight)`
  margin: 0 8px;
  & > path {
    stroke: ${({ theme }) => theme.palette.text.disabled};
  }
`;

export default Breadcrumbs;
