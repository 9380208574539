import { collection, doc, onSnapshot } from 'firebase/firestore';
import { createContext, useEffect, useMemo, useState } from 'react';
import { db } from '../firebaseConfig';
import Loading from '../components/Loading/Loading';

type RentCollectedData = {
  rentDueMonthly: {
    [month: string]: number;
  };
  rentCollectedMonthly: {
    [month: string]: {
      [day: string]: number;
    };
  };
  currentRentCollectedPerc: number;
  currentRentDue: number;
  currentRentMissing: number;
  tenancies: number;
};

const initialValue: RentCollectedData = {
  rentDueMonthly: {},
  rentCollectedMonthly: {},
  currentRentCollectedPerc: 0,
  currentRentDue: 0,
  currentRentMissing: 0,
  tenancies: 0,
};

export const RentCollectedContext =
  createContext<RentCollectedData>(initialValue);

type RentCollectedProviderProps = {
  children: React.ReactNode;
};

const RentCollectedProvider: React.FC<RentCollectedProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<RentCollectedData>(initialValue);
  const [isLoadingDue, setIsLoadingDue] = useState(true);
  const [isLoadingCollected, setIsLoadingCollected] = useState(true);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(true);
  const isLoading = isLoadingDue || isLoadingCollected || isLoadingMetrics;

  useEffect(() => {
    const dueRef = collection(db, 'investis-rent-due-monthly');
    const unsubscribeDue = onSnapshot(dueRef, (snapshot) => {
      if (snapshot.empty) {
        return;
      }

      const rentDueMonthly = Object.fromEntries(
        snapshot.docs.map((doc) => [doc.id, doc.data().rent_due])
      );
      const currentRentDue = Object.values(rentDueMonthly).at(-2);

      setData((data) => ({
        ...data,
        rentDueMonthly,
        currentRentDue,
      }));
      setIsLoadingDue(false);
    });

    const collectedRef = collection(db, 'investis-rent-collected-daily');
    const unsubscribeCollected = onSnapshot(collectedRef, (snapshot) => {
      if (snapshot.empty) {
        return;
      }

      const rentCollectedMonthly = Object.fromEntries(
        snapshot.docs.map((doc) => [
          doc.id,
          Object.fromEntries(
            Object.entries(doc.data() as { [day: string]: number }).sort(
              (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
            )
          ),
        ])
      );

      setData((data) => ({
        ...data,
        rentCollectedMonthly,
      }));
      setIsLoadingCollected(false);
    });

    const docRef = doc(
      db,
      'metrics',
      'rent_collected',
      new Date().getFullYear().toString(),
      new Date().getMonth().toString().padStart(2, '0')
    );
    const unsubscribeMetrics = onSnapshot(docRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }
      const tenancies = snapshot.data()?.from_tenancies ?? 0;

      setData((data) => ({
        ...data,
        tenancies,
      }));
      setIsLoadingMetrics(false);
    });

    return () => {
      unsubscribeDue();
      unsubscribeCollected();
      unsubscribeMetrics();
    };
  }, []);

  const additionalData = useMemo(() => {
    const { rentCollectedMonthly, currentRentDue } = data;

    const previousMonthRentCollected =
      Object.values(rentCollectedMonthly).at(-2);
    if (!previousMonthRentCollected) {
      return;
    }

    const currentRentCollected = Object.values(previousMonthRentCollected).at(
      -1
    );

    const currentRentCollectedPerc =
      ((currentRentCollected ?? 0) / currentRentDue) * 100;

    const currentRentMissing = currentRentDue - (currentRentCollected ?? 0);

    return {
      currentRentCollectedPerc,
      currentRentMissing,
    };
  }, [data]);

  return (
    <RentCollectedContext.Provider value={{ ...data, ...additionalData }}>
      {isLoading ? <Loading /> : children}
    </RentCollectedContext.Provider>
  );
};

export default RentCollectedProvider;
