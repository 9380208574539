import { Box, Typography } from "@mui/material";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import AreaChart from "../chart/AreaChart/AreaChart";
import { OccupancyContext } from "../../context/OccupancyContext";
import { GridColDef } from "@mui/x-data-grid";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import PageTitle from "../PageTitle/PageTitle";
import { formatDate } from "../../helpers/DateHelpers";
import { humanizeNumber } from "../../helpers/NumberHelpers";
import DataGrid from "../DataGrid/DataGrid";

type OccupancyEntry = {
  id: string;
  unit_ref: string;
  unit_type: string;
  floor_desc: string;
  unit_address: string;
  monthly_rent_net: string;
  days_vacant: number;
};

const OccupancyDetails: FC = () => {
  const data = useContext(OccupancyContext);

  const [vacantUnits, setVacantUnits] = useState<OccupancyEntry[]>([]);

  useEffect(() => {
    const getData = async () => {
      const docRef = collection(db, "investis-units-vacant");
      const colSnap = await getDocs(docRef);

      setVacantUnits(
        colSnap.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            monthly_rent_net: `${humanizeNumber(data.monthly_rent_net)} CHF`,
            unit_address: `${data.street}, ${data.postcode} ${data.city}`,
          } as OccupancyEntry;
        })
      );
    };

    getData();
  });

  const columns: GridColDef[] = [
    {
      field: "unit_ref",
      headerName: "Ref",
      flex: 2,
    },
    {
      field: "unit_type",
      headerName: "Unit Type",
      flex: 2,
    },
    {
      field: "floor_desc",
      headerName: "Floor",
      flex: 2,
    },
    {
      field: "unit_address",
      headerName: "Address",
      flex: 3,
    },
    {
      field: "days_vacant",
      headerName: "Days Vacant",
      flex: 1,
    },
    {
      field: "monthly_rent_net",
      headerName: "Rent Net",
      flex: 1,
    },
  ];

  const graphData = useMemo(() => {
    const startIndex = data.monthly.findIndex(
      (item) => item.year === 2023 && item.month === 1
    );
    const entries = data.monthly.slice(startIndex).map((item) => ({
      value1: item.occupancy,
      value2: item.units_vacant_count,
      label: formatDate(new Date(`${item.month}-01-${item.year}`), {
        day: false,
      }),
    }));

    const occupancyDomain = [
      Math.min(...entries.map((item) => parseFloat(item.value1.toFixed(2)))) -
        1,
      100,
    ];

    const unitsVacantDomain = [
      Math.min(...entries.map((item) => item.value2)) - 1,
      Math.max(...entries.map((item) => item.value2)) + 1,
    ];

    return {
      occupancyGraph: {
        data: entries.map((item) => ({
          value: item.value1.toFixed(2),
          label: item.label,
        })),
        domain: occupancyDomain,
      },
      unitsVacantGraph: {
        data: entries.map((item) => ({
          value: item.value2,
          label: item.label,
        })),
        domain: unitsVacantDomain,
      },
    };
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <PageTitle title="Occupancy" />
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="subtitle2" fontWeight="bold">
          Occupancy (%)
        </Typography>
        <AreaChart
          values={graphData.occupancyGraph.data}
          domain={graphData.occupancyGraph.domain as [number, number]}
          width="100%"
          height="30vh"
          tooltipTitle="Occupancy"
          unit="%"
          showAxisUnit={true}
          props={{
            syncId: "x",
          }}
        />
        <Typography variant="subtitle2" fontWeight="bold">
          Number of Vacant Units
        </Typography>
        <AreaChart
          values={graphData.unitsVacantGraph.data}
          domain={graphData.unitsVacantGraph.domain as [number, number]}
          width="100%"
          height="30vh"
          tooltipTitle="Vacant Units"
          props={{
            syncId: "x",
          }}
        />
        <Typography variant="subtitle2" fontWeight="bold">
          List of Vacant Units
        </Typography>
        <DataGrid
          height="400px"
          columns={columns}
          rows={vacantUnits}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
          disableDensitySelector={true}
          hideFooter={true}
          initialState={{
            sorting: {
              sortModel: [{ field: "days_vacant", sort: "desc" }],
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default OccupancyDetails;
