import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UiState = {
  mode: "light" | "dark";
  fade: boolean;
};

const initialValue: UiState = {
  mode: "dark",
  fade: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialValue,
  reducers: {
    setMode: (state, action: PayloadAction<UiState["mode"]>) => {
      state.mode = action.payload;
    },
    setFade: (state, action: PayloadAction<UiState["fade"]>) => {
      state.fade = action.payload;
    },
  },
});

export const selectMode = (state: { ui: UiState }) => state.ui.mode;

export const selectFade = (state: { ui: UiState }) => state.ui.fade;

export const { setMode, setFade } = uiSlice.actions;

export default uiSlice.reducer;
