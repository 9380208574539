import { collection, onSnapshot } from 'firebase/firestore';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import Loading from '../components/Loading/Loading';

export type Building = {
  address: string;
  canton: string;
  lat: number;
  lng: number;
};

type BuildingsData = {
  [id: string]: Building;
};

export const BuildingsContext = createContext<BuildingsData>({});

type BuildingsProviderProps = {
  children: ReactNode;
};

const BuildingsProvider: FC<BuildingsProviderProps> = ({ children }) => {
  const [data, setData] = useState<BuildingsData>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const collectionRef = collection(db, 'buildings_geocoded');
    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
      if (snapshot.empty) {
        return;
      }

      const buildingsData = Object.fromEntries(
        snapshot.docs.map((doc) => [doc.id, doc.data() as Building])
      );

      setData(buildingsData);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <BuildingsContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </BuildingsContext.Provider>
  );
};

export default BuildingsProvider;
