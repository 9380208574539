export const formatDate = (
  date: Date,
  options?: { year?: boolean; day?: boolean }
): string => {
  const format: Intl.DateTimeFormatOptions = {
    month: "short",
  };
  if (options?.year !== false) {
    format.year = "2-digit";
  }
  if (options?.day !== false) {
    format.day = "numeric";
  }
  return date.toLocaleDateString("default", format);
};
