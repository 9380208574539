import { DotProps, LegendProps, TooltipProps } from "recharts";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
export const ElectricityDot = (props: DotProps) => {
  const { cx, cy } = props;

  if (!cx || !cy) return null;
  const width = 8;
  const height = 8;
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg
      x={cx - width / 2}
      y={cy - height / 2}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width={width}
        height={height}
        rx={width / 4}
        fill="#FFD029"
      />
    </svg>
  );
};

export const WaterDot = (props: DotProps) => {
  const { cx, cy } = props;

  if (!cx || !cy) return null;
  const width = 10;
  const height = 10;

  return (
    <svg
      x={cx - width / 2}
      y={cy - height / 2}
      width={width}
      height={height}
      viewBox="0 0 6 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.13398 0.499999C2.51888 -0.166667 3.48113 -0.166667 3.86603 0.5L5.59808 3.5C5.98298 4.16667 5.50185 5 4.73205 5H1.26795C0.498148 5 0.0170237 4.16667 0.401924 3.5L2.13398 0.499999Z"
        fill="#429AF2"
      />
    </svg>
  );
};

export const HeatingDot = (props: DotProps) => {
  const { cx, cy } = props;

  if (!cx || !cy) return null;
  const width = 9;
  const height = 8;
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg
      x={cx - width / 2}
      y={cy - height / 2}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2221_41905)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.79485 0.328411C3.99502 0.168289 4.24372 0.0810547 4.50005 0.0810547C4.75639 0.0810547 5.00509 0.168289 5.20525 0.328411L8.07648 2.6254C8.26078 2.7729 8.39421 2.97446 8.458 3.20173C8.52179 3.42901 8.51273 3.67056 8.43209 3.89242L7.29188 7.02895C7.21269 7.24679 7.06836 7.43497 6.87851 7.56793C6.68866 7.7009 6.46248 7.7722 6.23069 7.77216H2.76941C2.53763 7.7722 2.31145 7.7009 2.1216 7.56793C1.93174 7.43497 1.78742 7.24679 1.70822 7.02895L0.568011 3.8928C0.48729 3.67089 0.478184 3.42926 0.541975 3.2019C0.605767 2.97455 0.739244 2.77292 0.923622 2.6254L3.79485 0.328411Z"
          fill="#FF837B"
        />
      </g>
      <defs>
        <clipPath id="clip0_2221_41905">
          <rect width="8" height="8" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
interface MazoutDotProps extends DotProps {
  index?: number;
}

export const MazoutDot: React.FC<MazoutDotProps> = (props) => {
  const { cx, cy } = props;

  if (!cx || !cy) return null;
  const width = 8;
  const height = 8;
  const viewBox = `0 0 0.24 0.24`;

  return (
    <svg
      x={cx - width / 2}
      y={cy - height / 2}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.021 0.085a0.05 0.05 0 0 0 0 0.071l0.063 0.063a0.05 0.05 0 0 0 0.071 0l0.063 -0.063a0.05 0.05 0 0 0 0 -0.071L0.155 0.021a0.05 0.05 0 0 0 -0.071 0z"
        fill="#606976"
      />
    </svg>
  );
};

const getLegendIcon = (value: string) => {
  switch (value) {
    case "electricity":
      return (
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.5" width="8" height="8" rx="2" fill="#FFD029" />
        </svg>
      );
    case "gas":
      return (
        <svg
          width="9"
          height="8"
          viewBox="0 0 9 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" width="8" height="8" rx="4" fill="#3DC092" />
        </svg>
      );
    case "water":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="8"
          viewBox="0 0 9 8"
          fill="none"
        >
          <path
            d="M3.20096 1.25C3.77831 0.25 5.22169 0.25 5.79904 1.25L8.39711 5.75C8.97446 6.75 8.25278 8 7.09808 8H1.90192C0.747223 8 0.0255356 6.75 0.602886 5.75L3.20096 1.25Z"
            fill="#429AF2"
          />
        </svg>
      );
    case "heating":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="8"
          viewBox="0 0 9 8"
          fill="none"
        >
          <g clipPath="url(#clip0_2221_41905)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.79485 0.328411C3.99502 0.168289 4.24372 0.0810547 4.50005 0.0810547C4.75639 0.0810547 5.00509 0.168289 5.20525 0.328411L8.07648 2.6254C8.26078 2.7729 8.39421 2.97446 8.458 3.20173C8.52179 3.42901 8.51273 3.67056 8.43209 3.89242L7.29188 7.02895C7.21269 7.24679 7.06836 7.43497 6.87851 7.56793C6.68866 7.7009 6.46248 7.7722 6.23069 7.77216H2.76941C2.53763 7.7722 2.31145 7.7009 2.1216 7.56793C1.93174 7.43497 1.78742 7.24679 1.70822 7.02895L0.568011 3.8928C0.48729 3.67089 0.478184 3.42926 0.541975 3.2019C0.605767 2.97455 0.739244 2.77292 0.923622 2.6254L3.79485 0.328411Z"
              fill="#FF837B"
            />
          </g>
          <defs>
            <clipPath id="clip0_2221_41905">
              <rect
                width="8"
                height="8"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case "mazout":
      return (
        <svg
          width="8px"
          height="8px"
          viewBox="0 0 0.24 0.24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.021 0.085a0.05 0.05 0 0 0 0 0.071l0.063 0.063a0.05 0.05 0 0 0 0.071 0l0.063 -0.063a0.05 0.05 0 0 0 0 -0.071L0.155 0.021a0.05 0.05 0 0 0 -0.071 0z"
            fill="#606976"
          />
        </svg>
      );
    default:
      return "";
  }
};

export const CustomLegend = (props: LegendProps) => {
  const { payload } = props;
  if (!payload) return null;

  return (
    <LegendRoot>
      {payload.map((entry, index) => (
        <LegendItem key={`item-${index}`}>
          {getLegendIcon(entry.value)}
          <Typography variant="caption">
            {entry.value.charAt(0).toUpperCase() + entry.value.slice(1)}
          </Typography>
        </LegendItem>
      ))}
    </LegendRoot>
  );
};

const LegendRoot = styled("ul")`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 0;
`;

const LegendItem = styled("li")`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CustomTooltip = (props: TooltipProps<number, string>) => {
  const { active, payload, label } = props;

  if (active && payload && payload.length) {
    return (
      <TooltipRoot display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle2" align="center">
          Year {label}
        </Typography>
        <Box display="flex" flexDirection="column" gap={0.5}>
          {payload.map((entry, index) => (
            <Box display="flex" alignItems="center" gap={1} key={index}>
              <Dot color={entry.color} />
              <Box width="100%" display="flex" gap={2}>
                <Typography variant="body2">
                  {entry.dataKey
                    ? (entry.dataKey as string).charAt(0).toUpperCase() +
                      (entry.dataKey as string).slice(1)
                    : ""}
                  :
                </Typography>
                <Typography variant="body2" marginLeft="auto">
                  {entry.value}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </TooltipRoot>
    );
  } else {
    return null;
  }
};

const TooltipRoot = styled(Box)`
  border-radius: 12px;
  background: ${(props) => props.theme.cardBackground};
  backdrop-filter: blur(10px);
  padding: 8px;
`;
const Dot = styled(Box, { shouldForwardProp: (prop) => prop !== "color" })<{
  color?: string;
}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.color ?? props.theme.palette.primary.main};
`;
