import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PageTitle from '../PageTitle/PageTitle';

import chevronRight from '../../assets/icons/chevronRight.svg';
import {
  GridColDef,
  GridPagination,
  GridRenderCellParams,
  gridDateComparator,
  gridNumberComparator,
  gridPageCountSelector,
  gridStringOrNumberComparator,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import {
  faFileExcel,
  faFileCsv,
  faFilePdf,
  faFileLines,
} from '@fortawesome/free-solid-svg-icons';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Breadcrumbs,
  Link,
  Pagination,
  styled,
  TablePaginationProps,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { filesize } from 'filesize';
import DataGrid from '../DataGrid/DataGrid';
import { useTheme } from '@mui/material/styles';
import { AuthContext } from '../../context/AuthContext';

const CustomPagination: FC<
  Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>
> = ({ page, onPageChange, className }) => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
};

const DataGridPagination: FC = () => {
  return (
    <Box width="100%" display="flex" justifyContent="center">
      <GridPagination
        ActionsComponent={CustomPagination}
        rowsPerPage={100}
        rowsPerPageOptions={[]}
        labelDisplayedRows={() => ''}
        sx={{
          '.MuiToolbar-root': {
            padding: 0,
            margin: 0,
          },
          '.MuiPagination-root': {
            padding: 0,
            margin: '0 !important',
          },
          '.MuiPaginationItem-root.Mui-selected': {
            backgroundColor: '#dee3e7 !important',
            color: '#000 !important',
          },
        }}
      />
    </Box>
  );
};

const rootFolders: ReportEntry[] = [
  {
    id: 'investis-monthly-reports',
    name: 'Investis Monthly Reports',
    url: 'investis-monthly-reports',
    type: 'folder',
    created: '',
    updated: '',
    size: '',
    hideDownload: true,
  },
  {
    id: 'perty-occupancy',
    name: 'État Locatif History',
    url: 'perty-etat-locatif',
    type: 'folder',
    created: '',
    updated: '',
    size: '',
    hideDownload: true,
  },
];

interface ReportEntry {
  id: string;
  name: string;
  type: string;
  created: string;
  updated: string;
  size: number | string;
  hideDownload: boolean;
  url?: string;
  icon?: any;
}

const Reports: FC = () => {
  const theme = useTheme();
  const [path, setPath] = useState('');
  const { reports, loading, handleDownload } = useReports(path);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'NameHeader',
      cellClassName: 'NameCell',
      flex: 7,
      getSortComparator: (sortDirection) => {
        const modifier = sortDirection === 'desc' ? -1 : 1;
        return (value1, value2, cellParams1, cellParams2) => {
          if (value1 === '..') {
            return -1;
          }
          if (value2 === '..') {
            return 1;
          }
          return (
            modifier *
            gridStringOrNumberComparator(
              value1,
              value2,
              cellParams1,
              cellParams2
            )
          );
        };
      },
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          paddingLeft={params.id !== '..' && path !== '' ? 2 : 0}
        >
          <FontAwesomeIcon
            icon={
              params.row.icon ??
              (params.row.type === 'folder'
                ? faFolder
                : params.row.name.includes('xlsx')
                ? faFileExcel
                : params.row.name.includes('pdf')
                ? faFilePdf
                : params.row.name.includes('csv')
                ? faFileCsv
                : faFileLines)
            }
            color={
              params.row.name.includes('xlsx')
                ? '#0fa144'
                : params.row.name.includes('pdf')
                ? '#d32f2f'
                : params.row.name.includes('csv')
                ? '#0fa144'
                : params.row.type === 'folder'
                ? '#aeb7be'
                : theme.palette.primary.main
            }
          />
          <Box
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {params.row.name}
          </Box>
        </Box>
      ),
    },
    {
      field: 'created',
      headerName: 'Created',
      flex: 3,
      getSortComparator: (sortDirection) => {
        const modifier = sortDirection === 'desc' ? -1 : 1;
        return (value1, value2, cellParams1, cellParams2) => {
          if (value1 === '') {
            return -1;
          }
          if (value2 === '') {
            return 1;
          }
          return (
            modifier *
            gridDateComparator(
              new Date(value1 as string),
              new Date(value2 as string),
              cellParams1,
              cellParams2
            )
          );
        };
      },
      renderCell: (params) => (
        <Typography
          component="span"
          variant="body2"
          color={theme.palette.text.secondary}
        >
          {params.row.created}
        </Typography>
      ),
    },
    {
      field: 'updated',
      headerName: 'Last updated',
      flex: 3,
      getSortComparator: (sortDirection) => {
        const modifier = sortDirection === 'desc' ? -1 : 1;
        return (value1, value2, cellParams1, cellParams2) => {
          if (value1 === '') {
            return -1;
          }
          if (value2 === '') {
            return 1;
          }
          return (
            modifier *
            gridDateComparator(
              new Date(value1 as string),
              new Date(value2 as string),
              cellParams1,
              cellParams2
            )
          );
        };
      },
      renderCell: (params) => (
        <Typography
          component="span"
          variant="body2"
          color={theme.palette.text.secondary}
        >
          {params.row.updated}
        </Typography>
      ),
    },
    {
      field: 'size',
      headerName: 'Size',
      type: 'number',
      flex: 2,
      getSortComparator: (sortDirection) => {
        const modifier = sortDirection === 'desc' ? -1 : 1;
        return (value1, value2, cellParams1, cellParams2) => {
          if (value1 === '') {
            return -1;
          }
          if (value2 === '') {
            return 1;
          }
          return (
            modifier *
            gridNumberComparator(value1, value2, cellParams1, cellParams2)
          );
        };
      },
      renderCell: (params) => (
        <Typography
          component="span"
          variant="body2"
          color={theme.palette.text.secondary}
        >
          {params.row.size && filesize(params.row.size)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <Box
          width="100%"
          textAlign="center"
          onClick={(event) => {
            event.stopPropagation();
            handleDownload(params);
          }}
          sx={{ cursor: 'pointer' }}
        >
          {!params.row.hideDownload && (
            <Tooltip
              title={
                <TooltipContent>
                  <Typography variant="body2">Download</Typography>
                </TooltipContent>
              }
              enterDelay={500}
              componentsProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: 'transparent',
                    },
                  },
                },
              }}
            >
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                color={theme.palette.primary.light}
              />
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const rows: ReportEntry[] = useMemo(() => {
    if (path === '') {
      return rootFolders;
    } else {
      const currentReports: ReportEntry[] = [
        {
          id: '..',
          name: '..',
          type: 'folder',
          created: '',
          updated: '',
          size: '',
          hideDownload: true,
          icon: faFolderOpen,
        },
      ];
      if (!loading) {
        currentReports.push(
          ...reports.map((report) => ({
            id: report.blob_name,
            name: report.blob_name,
            type: report.blob_type,
            created: new Date(report.blob_created).toLocaleString('default', {
              weekday: 'short',
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }),
            updated: new Date(report.blob_last_updated).toLocaleString(
              'default',
              {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              }
            ),
            size: report.blob_size,
            hideDownload: false,
          }))
        );
      }

      return currentReports;
    }
  }, [loading, path, reports]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <PageTitle title="Reports" />
      <Box height="60vh">
        <Box marginY="16px">
          <Breadcrumbs
            separator={<img alt="chevron" src={chevronRight} />}
            sx={{
              '.MuiBreadcrumbs-li': {
                display: 'flex',
              },
              '.MuiBreadcrumbs-separator': {
                margin: 0,
              },
            }}
          >
            {[...path.split('/')].map((folder, index) => (
              <Link
                key={index}
                variant="body2"
                color="inherit"
                sx={{
                  color: '#667180',
                  textDecoration: 'none',
                  fontSize: '12px',
                  lineHeight: '12px',
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                }}
                onClick={() =>
                  setPath(
                    path.substring(0, path.indexOf(folder) + folder.length)
                  )
                }
              >
                {folder === ''
                  ? 'Reports'
                  : rootFolders.find((f) => f.url === folder)?.name ??
                    folder.replaceAll('-', ' ')}
              </Link>
            ))}
          </Breadcrumbs>
        </Box>
        <DataGrid
          height="500px"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
            sorting: {
              sortModel: [
                {
                  field: 'created',
                  sort: 'desc',
                },
              ],
            },
          }}
          rows={rows}
          columns={columns}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
          disableDensitySelector={true}
          loading={loading}
          slots={{
            pagination: DataGridPagination,
          }}
          getRowId={(row) => row.name}
          onRowClick={(params) => {
            if (params.row.type === 'folder') {
              if (params.row.name === '..') {
                setPath(path.substring(0, path.lastIndexOf('/')));
              } else {
                setPath(`${path}/${params.row.url ?? params.row.name}`);
              }
            }
          }}
          getRowClassName={(params) =>
            params.row.type === 'folder' ? 'clickable' : ''
          }
          sx={{
            '& .MuiDataGrid-row': {
              '&.clickable': {
                cursor: 'pointer',
              },

              '&:hover': {
                backgroundColor: theme.cardBackground,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

interface Report {
  blob_name: string;
  blob_type: string;
  blob_size: number;
  blob_created: string;
  blob_last_updated: string;
}

const useReports = (path: string) => {
  const { user } = useContext(AuthContext);
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchReports = useCallback(async () => {
    if (!user || path === '') {
      return;
    }
    setLoading(true);
    const token = await user?.getIdToken();
    const response = await fetch(
      `https://metricsapiprod-746vyhgdwq-oa.a.run.app/reports/folders${
        path ? `${path}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();

    setReports(data.blobs);
    setLoading(false);
  }, [path, user]);

  const handleDownload = useCallback(
    async (params: GridRenderCellParams) => {
      if (!user) {
        return;
      }
      const token = await user.getIdToken();
      const pathname = encodeURIComponent(`${path}/${params.row.name}`);
      const response = await fetch(
        `https://metricsapiprod-746vyhgdwq-oa.a.run.app/reports/${
          params.row.type === 'folder' ? 'download-folder' : 'download'
        }${pathname}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = params.row.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    [path, user]
  );

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  return { reports, loading, handleDownload };
};

const TooltipContent = styled(Box)`
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 12px;
  gap: 4px;
`;

export default Reports;
