import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Box, styled, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export interface SidebarItemProps {
  id: string;
  active: boolean;
  name?: string;
  icon?: IconProp;
  src?: string;
  applyActiveColor?: boolean;
  customRoute?: string;
  onClick?: () => void;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  id,
  name,
  icon,
  src,
  active,
  applyActiveColor,
  customRoute,
  onClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  function handleClick() {
    if (onClick) {
      onClick();
    } else {
      navigate(`/${customRoute ?? id}`);
    }
  }

  return (
    <SidebarItemRoot active={active} onClick={handleClick}>
      {icon ? (
        <FontAwesomeIcon
          icon={icon as IconProp}
          width={24}
          height={24}
          fontSize={16}
          color={
            active ? theme.palette.common.white : theme.palette.text.secondary
          }
        />
      ) : (
        <Logo src={src as string} alt={name} />
      )}
      {name && (
        <Typography
          variant="body2"
          fontWeight="bold"
          color={
            active ? theme.palette.common.white : theme.palette.text.secondary
          }
        >
          {name}
        </Typography>
      )}
    </SidebarItemRoot>
  );
};

const SidebarItemRoot = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(
  ({ theme, active }) => `
  width: 180px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  height: 36px;
  border-radius: 8px;
  flex-shrink: 0;
  padding-left: 12px;
  margin-left: 12px;
  color: ${active ? theme.palette.common.white : theme.palette.text.secondary};
  font-size: 14px;
  font-weight: 600;
  background: ${active ? theme.palette.secondary.main : "transparent"};
  cursor: pointer;
`
);

const Logo = styled("img")`
  width: 24px;
  filter: ${({ theme }) => theme.iconFilter ?? "none"};
`;

export default SidebarItem;
