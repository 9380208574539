import { Box, styled, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type ModeSwitchProps = {
  modes: [string, string];
  currentMode: string;
  width: number | string;
  height: number | string;
  onModeClick: (mode: string) => void;
  modeLabels?: [ReactNode, ReactNode];
};

const ModeSwitch: FC<ModeSwitchProps> = ({
  modes,
  currentMode,
  width,
  height,
  onModeClick,
  modeLabels,
}) => {
  return (
    <ModeSwitchRoot
      width={width}
      height={height}
      onClick={() =>
        onModeClick(modes.find((mode) => mode !== currentMode) ?? '')
      }
    >
      {modes.map((mode, index) => (
        <ModeSwitchItem key={mode} active={mode === currentMode}>
          <Typography variant="body2">{modeLabels?.[index] ?? mode}</Typography>
        </ModeSwitchItem>
      ))}
    </ModeSwitchRoot>
  );
};

const ModeSwitchRoot = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  border-radius: 8px;
  background: ${({ theme }) => theme.cardBackground};
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
`;

const ModeSwitchItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(
  ({ theme, active }) => `
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-grow: 1;

  cursor: pointer;

  ${
    active &&
    `
    border-radius: 6px;
  background-color: ${theme.palette.secondary.main};

  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);

    & > p {
    color: white;
  }
  `
  }
`
);

export default ModeSwitch;
