import { Box, styled, Typography } from "@mui/material";
import { FC, useContext, useMemo } from "react";
import AreaChart from "../chart/AreaChart/AreaChart";
import { GridColDef } from "@mui/x-data-grid";
import PageTitle from "../PageTitle/PageTitle";
import { formatDate } from "../../helpers/DateHelpers";
import {
  TechnicalHealthContext,
  WorkOrderType,
} from "../../context/TechnicalHealthContext";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  SectorProps,
  Tooltip,
} from "recharts";
import SimpleChartTooltip from "../chart/SimpleChartTooltip";
import { humanizeNumber } from "../../helpers/NumberHelpers";
import DataGrid from "../DataGrid/DataGrid";
import { useTheme } from "@mui/material/styles";

const TechnicalHealthDetails: FC = () => {
  const theme = useTheme();
  const data = useContext(TechnicalHealthContext);

  const columns: GridColDef[] = [
    {
      field: "contractor_full_name",
      headerName: "Contractor Name",
      flex: 3,
    },
    {
      field: "counts",
      headerName: "Frequency (%)",
      flex: 1,
    },
  ];

  const rows = useMemo(
    () =>
      Object.values(data.daily)
        .at(-1)
        ?.contractors_assigned.map((item) => ({
          id: item.contractor_full_name,
          contractor_full_name:
            item.contractor_full_name === "No contractor assigned"
              ? "Other contractors"
              : item.contractor_full_name,
          counts: humanizeNumber(item.normalized_counts * 100) + "%",
        })),
    [data.daily]
  );

  const areaChartData = useMemo(() => {
    const entries = Object.entries(data.daily).map(([key, value]) => {
      const keyComponents = key.split("-").map((item) => parseInt(item));

      return {
        value1: 100 - value.technical_health,
        label: formatDate(
          new Date(keyComponents[0], keyComponents[1], keyComponents[2])
        ),
      };
    });

    const unitsWithIssuesDomain = [
      Math.min(...entries.map((item) => parseFloat(item.value1.toFixed(2)))) -
        10,
      Math.max(...entries.map((item) => parseFloat(item.value1.toFixed(2)))) +
        10,
    ];

    return {
      unitsWithIssuesGraph: {
        data: entries.map((item) => ({
          value: item.value1.toFixed(2),
          label: item.label,
        })),
        domain: unitsWithIssuesDomain,
      },
    };
  }, [data]);

  const pieData = useMemo(() => {
    let entries = Object.values(data.daily).at(-1)?.wo_types ?? [];

    const decreaseEntriesCount = (
      entries: WorkOrderType[],
      count: number = 1
    ): WorkOrderType[] => {
      if (entries.length <= 7) {
        return entries;
      }
      return decreaseEntriesCount(
        entries.filter((item) => item.counts > count),
        count + 1
      );
    };

    const decreasedEntries = decreaseEntriesCount(entries).map(
      (item, index) => ({
        label: item.workorder_service_type_name,
        value: Math.round(item.normalized_counts * 10000) / 100,
        color: theme.chartPalette[index],
      })
    );

    const other = {
      label: " Other",
      value:
        Math.round(
          entries
            .filter(
              (item) =>
                !decreasedEntries
                  .map((item) => item.label)
                  .includes(item.workorder_service_type_name)
            )
            .reduce((acc, item) => acc + item.normalized_counts, 0) * 10000
        ) / 100,
      tooltipLabel: "Other",
      color: theme.chartPalette.at(-1),
    };

    const values = decreasedEntries.map((item) => ({
      ...item,
      label: item.label.replace(/([A-Z])/g, " $1").replace("/ ", "/"),
      tooltipLabel: item.label.replace(/([A-Z])/g, " $1").replace("/ ", "/"),
    }));

    return [...values, other];
  }, [data.daily, theme.chartPalette]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <PageTitle title="Technical Health" />
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="subtitle2" fontWeight="bold">
          Units with Technical Issues over Last 3 Months (%)
        </Typography>
        <AreaChart
          values={areaChartData.unitsWithIssuesGraph.data}
          domain={areaChartData.unitsWithIssuesGraph.domain as [number, number]}
          width="100%"
          height="40vh"
          tooltipTitle="Units with Issues"
          unit="%"
          hideDots
          props={{
            syncId: "x",
          }}
        />
        <Box display="flex" marginBottom={2} gap={2}>
          <Card width="50%" display="flex" flexDirection="column" gap={2}>
            <Typography variant="subtitle2" fontWeight="bold">
              Issue Types
            </Typography>
            <ChartContainer width="80%" height="90%">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={pieData}
                    dataKey="value"
                    nameKey="label"
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={100}
                    fill="#8884d8"
                    legendType="circle"
                    paddingAngle={5}
                    cornerRadius={4}
                    activeShape={(props: SectorProps) => (
                      <Sector {...props} fillOpacity={0.8} />
                    )}
                  >
                    {pieData.map((_entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={theme.chartPalette[index]}
                        stroke="none"
                      />
                    ))}
                  </Pie>
                  <Legend
                    align="right"
                    verticalAlign="middle"
                    layout="vertical"
                    iconSize={12}
                    formatter={(value) => (
                      <span
                        style={{
                          color: theme.palette.text.secondary,
                          fontSize: "12px",
                        }}
                      >
                        {value}
                      </span>
                    )}
                  />
                  <Tooltip
                    content={
                      <SimpleChartTooltip
                        title="Share of All Issues"
                        unit="%"
                      />
                    }
                  />
                </PieChart>
              </ResponsiveContainer>
            </ChartContainer>
          </Card>
          <Card width="50%" display="flex" flexDirection="column" gap={2}>
            <Typography variant="subtitle2" fontWeight="bold">
              Frequency of Contractors Assigned
            </Typography>
            <DataGrid
              height="400px"
              columns={columns}
              rows={rows}
              disableColumnMenu={true}
              disableRowSelectionOnClick={true}
              disableDensitySelector={true}
              hideFooter={true}
              rowHeight={36}
              columnHeaderHeight={42}
              initialState={{
                sorting: {
                  sortModel: [{ field: "days_vacant", sort: "desc" }],
                },
              }}
            />
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

const Card = styled(Box)`
  border-radius: 12px;
  background: ${({ theme }) => theme.cardBackground};
  padding: 20px;
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
`;

const ChartContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  align-self: center;

  border-radius: 12px;

  box-sizing: border-box;
  transition: 0.1s ease-out;
`;

export default TechnicalHealthDetails;
