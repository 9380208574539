import { Box, styled } from "@mui/material";
import React from "react";

interface FilterPillProps {
  title: string;
  value: string | number;
  onClickCustom?: () => void;
  displayX: boolean;
}

const FilterPill: React.FC<FilterPillProps> = ({
  title,
  value,
  onClickCustom,
  displayX,
}) => {
  return (
    <FilterPillRoot onClick={onClickCustom}>
      {title}: {value}
      {displayX ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M15 5L5 15M5 5L15 15"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : null}
    </FilterPillRoot>
  );
};

const FilterPillRoot = styled(Box)(
  ({ theme }) => `
    display: flex;
    width: fit-content;
    padding: 2px 10px;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-radius: 9999px;
    background: ${theme.palette.primary.light};
    color: white;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    box-shadow: 0px 1px 8px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;

    &:hover {
    scale: 1.01;
        transition: 0.1s ease-in-out;
    }
`
);

export default FilterPill;
