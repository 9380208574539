import { renderToStaticMarkup } from "react-dom/server";
import { Cluster, Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import { Building } from "../../../context/BuildingsContext";
import { useMap } from "@vis.gl/react-google-maps";
import {
  FC,
  useState,
  useMemo,
  useEffect,
  useCallback,
  createElement,
} from "react";
import BuildingMarker from "./BuildingMarker";
import BuildingMarkerInfoWindow from "./BuildingMarkerInfoWindow";
import { useTheme } from "@mui/material/styles";

type ClusteredBuildingMarkersProps = {
  buildings: { [key: string]: Building & { color: string } };
};

const ClusteredBuildingMarkers: FC<ClusteredBuildingMarkersProps> = ({
  buildings,
}) => {
  const theme = useTheme();
  const [markers, setMarkers] = useState<Record<string, Marker>>({});
  const [selectedBuildingKey, setSelectedBuildingKey] = useState<
    string | null
  >();

  const selectedBuilding = useMemo(() => {
    const entry = Object.entries(buildings).find(
      ([key]) => key === selectedBuildingKey
    );
    if (!entry) return null;
    return {
      id: entry[0],
      ...entry[1],
    };
  }, [buildings, selectedBuildingKey]);

  const map = useMap();

  const clusterer = useMemo(() => {
    if (!map) return null;

    class Renderer {
      render(cluster: Cluster) {
        const count = cluster.markers?.length ?? 0;
        const position = cluster.position;

        const contentString = renderToStaticMarkup(
          createElement("div", {
            style: {
              width: 48,
              height: 48,
              position: "absolute",
              top: 0,
              left: 0,
              borderRadius: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: `radial-gradient(${theme.palette.primary.light} 30%, transparent 60%)`,
            },
            children: <span style={{ color: "white" }}>{count}</span>,
          })
        );

        const content = document.createElement("div");
        content.innerHTML = contentString;

        return new google.maps.marker.AdvancedMarkerElement({
          position,
          title: `${count} buildings`,
          content,
        });
      }
    }

    return new MarkerClusterer({ map, renderer: new Renderer() });
  }, [map, theme.palette.primary.light]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedBuildingKey(null);
  }, []);

  const handleMarkerClick = useCallback((key: string) => {
    setSelectedBuildingKey(key);
  }, []);

  const handleOpenStreetView = useCallback(
    (key: string) => {
      const sv = map?.getStreetView();
      sv?.setOptions({
        visible: true,
        position: {
          lat: buildings[key].lat,
          lng: buildings[key].lng,
        },
      });
    },
    [buildings, map]
  );

  return (
    <>
      {Object.entries(buildings).map(([key, value]) => (
        <BuildingMarker
          key={key}
          building={{
            key,
            address: value.address,
            position: { lat: value.lat, lng: value.lng },
            color: value.color,
          }}
          setRef={setMarkerRef}
          onClick={() => handleMarkerClick(key)}
        />
      ))}
      {selectedBuildingKey && (
        <BuildingMarkerInfoWindow
          anchor={markers[selectedBuildingKey]}
          building={selectedBuilding}
          onClose={handleInfoWindowClose}
          onOpenStreetView={() => handleOpenStreetView(selectedBuildingKey)}
        />
      )}
    </>
  );
};

export default ClusteredBuildingMarkers;
