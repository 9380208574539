import { TooltipProps } from "recharts";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

const SimpleChartTooltip = (
  props: TooltipProps<number, string> & {
    title: string;
    unit?: string;
    valueFormatter?: (value: number) => string;
  }
) => {
  const { active, payload, label, title, unit, valueFormatter } = props;
  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <CustomTooltipRoot
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <Typography variant="subtitle1" align="center">
          {title}
        </Typography>
        <Box display="flex" flexDirection="column" gap={0.5}>
          {payload.map((entry, index) => (
            <Box display="flex" alignItems="center" gap={1} key={index}>
              <Box
                width="10px"
                height="10px"
                borderRadius="50%"
                sx={{
                  backgroundColor:
                    entry.payload.color ?? theme.palette.primary.main,
                }}
              ></Box>
              <Typography variant="body2">
                {entry.payload.tooltipLabel ?? label}:
              </Typography>
              <Typography variant="body2">
                {valueFormatter?.(entry.value ?? 0) ?? entry.value}
                {unit === "%" ? "" : " "}
                {unit}
              </Typography>
            </Box>
          ))}
        </Box>
      </CustomTooltipRoot>
    );
  } else {
    return null;
  }
};

const CustomTooltipRoot = styled(Box)`
  border-radius: 12px;
  background: ${(props) => props.theme.cardBackground};
  backdrop-filter: blur(10px);
  padding: 8px;
`;

export default SimpleChartTooltip;
