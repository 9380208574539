import React, { useEffect, useState, createContext } from 'react';
import { collection, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Loading from '../components/Loading/Loading';

type PortfolioHealthWeeklyValues = {
  max_date: Timestamp;
  min_date: Timestamp;
  value: number;
};
type PortfolioHealthDailyValues = {
  date: string;
  value: number;
};

type PortfolioHealthData = {
  weekly: {
    [week: string]: PortfolioHealthWeeklyValues;
  };
  daily: {
    [day: string]: PortfolioHealthDailyValues;
  };
  current: number;
};

const initialValue: PortfolioHealthData = {
  weekly: {},
  daily: {},
  current: 0,
};

export const PortfolioHealthContext =
  createContext<PortfolioHealthData>(initialValue);

type PortfolioHealthProviderProps = {
  children: React.ReactNode;
};

const PortfolioHealthProvider: React.FC<PortfolioHealthProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<PortfolioHealthData>(initialValue);
  const [isLoadingDaily, setIsLoadingDaily] = useState(true);
  const [isLoadingWeekly, setIsLoadingWeekly] = useState(true);
  const isLoading = isLoadingDaily || isLoadingWeekly;

  useEffect(() => {
    const dailyRef = collection(db, 'investis-portfolio-health-daily');
    const weeklyRef = collection(db, 'investis-portfolio-health-weekly');

    const unsubscribeDaily = onSnapshot(dailyRef, async (snapshot) => {
      const dailyData = Object.fromEntries(
        snapshot.docs.map((doc) => [
          doc.id,
          doc.data() as PortfolioHealthDailyValues,
        ])
      );
      const current = Object.values(dailyData).at(-1)?.value ?? 0;
      setData((prev) => ({ ...prev, daily: dailyData, current }));
      setIsLoadingDaily(false);
    });

    const unsubscribeWeekly = onSnapshot(weeklyRef, async (snapshot) => {
      const weeklyData = Object.fromEntries(
        snapshot.docs.map((doc) => [
          doc.id,
          doc.data() as PortfolioHealthWeeklyValues,
        ])
      );
      setData((prev) => ({ ...prev, weekly: weeklyData }));
      setIsLoadingWeekly(false);
    });

    return () => {
      unsubscribeDaily();
      unsubscribeWeekly();
    };
  }, []);

  return (
    <PortfolioHealthContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </PortfolioHealthContext.Provider>
  );
};

export default PortfolioHealthProvider;
