import { InfoWindow } from "@vis.gl/react-google-maps";
import { FC, useContext, useMemo } from "react";
import { BudgetContext } from "../../../context/BudgetContext";
import { Building } from "../../../context/BuildingsContext";
import { formatNumber } from "../../../helpers/NumberHelpers";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Marker } from "@googlemaps/markerclusterer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";

type BuildingMarkerInfoWindowProps = {
  building: (Building & { id: string; color: string }) | null;
  anchor: Marker | null;
  onClose: () => void;
  onOpenStreetView: () => void;
};

const BuildingMarkerInfoWindow: FC<BuildingMarkerInfoWindowProps> = ({
  building,
  anchor,
  onClose,
  onOpenStreetView,
}) => {
  const theme = useTheme();
  const budgetData = useContext(BudgetContext);

  const data = useMemo(() => {
    if (!building) {
      return null;
    }
    const total =
      budgetData.total[`${new Date().getFullYear().toString().slice(-2)}TOTAL`];
    const buildingTotal = total[building.id];

    const thisMonth =
      budgetData.monthly[
        `${new Date().getFullYear().toString().slice(-2)}M${new Date()
          .getMonth()
          .toString()
          .padStart(2, "0")}`
      ];
    const buildingThisMonth = thisMonth[building.id];

    if (!buildingThisMonth || !buildingTotal) {
      return;
    }

    const spent = buildingThisMonth * buildingTotal;
    const spentPercentage = buildingThisMonth * 100;
    const over = spent - buildingTotal;

    return {
      address: building.address,
      total: `${formatNumber({
        value: buildingTotal,
        options: { round: true, format: ", " },
      })} CHF`,
      spent: `${formatNumber({
        value: spent,
        options: { round: true, format: ", " },
      })} CHF`,
      spentPercentage: `${spentPercentage.toFixed(2)}%`,
      over: `${formatNumber({
        value: over,
        options: { round: true, format: ", " },
      })} CHF`,
      color: building.color,
    };
  }, [budgetData.monthly, budgetData.total, building]);

  if (!data || !anchor) {
    return null;
  }

  return (
    <InfoWindow anchor={anchor} headerDisabled>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{
          backgroundColor: theme.cardBackground,
          borderRadius: 2,
          padding: "8px",
          backdropFilter: "blur(10px)",
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <div
            style={{
              width: 16,
              height: 16,
              border: `5px solid ${data.color}`,
              borderRadius: "50%",
            }}
          ></div>
          <Typography variant="body2" fontWeight="bold">
            {data.address.split(", ").slice(0, -1).join(", ")}
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <FontAwesomeIcon
              icon={faClose}
              fontSize={16}
              color={theme.palette.text.disabled}
            />
          </IconButton>
        </Box>
        <Typography variant="body2">
          <b>Budget:</b> {data.total}
        </Typography>
        <Typography variant="body2">
          <b>Spent:</b> {data.spent}
        </Typography>
        <Typography variant="body2">
          <b>Spent Percentage:</b> {data.spentPercentage}
        </Typography>
        {!data.over.includes("-") && (
          <Typography variant="body2">
            {data.over} <b>over budget</b>
          </Typography>
        )}
        <Button
          variant="text"
          onClick={onOpenStreetView}
          size="small"
          sx={{ textTransform: "none", color: theme.palette.secondary.light }}
        >
          Open Street View
        </Button>
      </Box>
    </InfoWindow>
  );
};

export default BuildingMarkerInfoWindow;
