import { collection, onSnapshot } from 'firebase/firestore';
import { createContext, useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import Loading from '../components/Loading/Loading';

interface UnitIncrease {
  city: string;
  current_net_rent: number;
  floor: string;
  postcode: string;
  proposed_net_rent: number;
  ref: string;
  rooms: string;
  street: string;
  main_tenant_name: string;
}

type CpiIncreasesMonthlyData = {
  candidates: number;
  increased_in: number;
  success_rate: number;
  total_increase_amount: number;
  total_increase_amount_proposed: number;
  total_increase_cumulative_sum: number;
  units: UnitIncrease[];
};

type CpiIncreasesData = {
  monthly: {
    [month: string]: CpiIncreasesMonthlyData;
  };
  currentIncreased: number;
  currentCumulativeIncreasedIn: number;
  currentCumulativeIncrease: number;
};

const initialValue: CpiIncreasesData = {
  monthly: {},
  currentIncreased: 0,
  currentCumulativeIncreasedIn: 0,
  currentCumulativeIncrease: 0,
};

export const CpiIncreasesContext =
  createContext<CpiIncreasesData>(initialValue);

type CpiIncreasesProviderProps = {
  children: React.ReactNode;
};

const CpiIncreasesProvider: React.FC<CpiIncreasesProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<CpiIncreasesData>(initialValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const ref = collection(db, 'investis-cpi-increases');
    const unsubscribe = onSnapshot(ref, (snapshot) => {
      if (snapshot.empty) {
        return;
      }

      const monthly = Object.fromEntries(
        snapshot.docs.map((doc) => [
          doc.id,
          doc.data() as CpiIncreasesMonthlyData,
        ])
      );

      const currentMonthKey = `${new Date()
        .getFullYear()
        .toString()
        .slice(-2)}M${(new Date().getMonth() + 1).toString().padStart(2, '0')}`;

      const currentMonth = Object.entries(monthly).find(
        ([key]) => key === currentMonthKey
      )?.[1];
      if (!currentMonth) {
        return;
      }
      const currentIncreased = currentMonth.success_rate;
      const currentCumulativeIncrease =
        currentMonth.total_increase_cumulative_sum;

      const currentCumulativeIncreasedIn = Object.entries(monthly)
        .filter(
          ([key]) =>
            key.includes(new Date().getFullYear().toString().slice(-2)) &&
            parseInt(key.slice(-2)) <= new Date().getMonth() + 1
        )
        .reduce((acc, [_, value]) => acc + value.increased_in, 0);

      setData({
        monthly,
        currentIncreased,
        currentCumulativeIncreasedIn,
        currentCumulativeIncrease,
      });
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <CpiIncreasesContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </CpiIncreasesContext.Provider>
  );
};

export default CpiIncreasesProvider;
