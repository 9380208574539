import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAdFt7bOVz1HM-PV7dwkGbHt274QHoVUWU",
  authDomain: "perty-metabase.firebaseapp.com",
  projectId: "perty-metabase",
  storageBucket: "perty-metabase.appspot.com",
  messagingSenderId: "940885628064",
  appId: "1:940885628064:web:47ddec5c8ff3f9d77da5c5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const db = getFirestore(app);

export { auth, db };