import React, { useEffect, useState, createContext } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Loading from '../components/Loading/Loading';

export const CaseStatusContext = createContext<{
  data: CaseStatus[];
}>({
  data: [],
});

interface CaseStatus {
  legal_cases: number;
  list_id: string;
  list_name: string;
  order: number;
}

type CasesStatusProviderProps = {
  children: React.ReactNode;
};

const CasesStatusProvider: React.FC<CasesStatusProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<CaseStatus[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const docRef = doc(
      db,
      'investis-legal-cases',
      'count_legal_cases_per_status'
    );
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      const dataList: CaseStatus[] = [];

      if (snapshot.exists()) {
        dataList.push(snapshot.data() as CaseStatus);
      }

      setData(dataList);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <CaseStatusContext.Provider value={{ data }}>
      {isLoading ? <Loading /> : children}
    </CaseStatusContext.Provider>
  );
};

export default CasesStatusProvider;
