import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";

type SecretsContextType = {
  maps_api_key?: string;
};

export const SecretsContext = createContext<SecretsContextType>({});

type SecretsProviderProps = {
  children: React.ReactNode;
};

const SecretsProvider: React.FC<SecretsProviderProps> = ({ children }) => {
  const [secrets, setSecrets] = useState<SecretsContextType>({});
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        return;
      }
      const token = await user?.getIdToken();
      const response = await fetch(
        "https://metricsapiprod-746vyhgdwq-oa.a.run.app/secrets/amp-maps-api-key",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.text();
      setSecrets({ maps_api_key: data });
    };

    fetchData();
  }, [user]);

  return (
    <SecretsContext.Provider value={secrets}>
      {children}
    </SecretsContext.Provider>
  );
};

export default SecretsProvider;
