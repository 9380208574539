import { Box, Typography } from "@mui/material";
import { FC, useContext, useMemo } from "react";
import AreaChart from "../chart/AreaChart/AreaChart";

import PageTitle from "../PageTitle/PageTitle";
import { formatDate } from "../../helpers/DateHelpers";
import { RentCollectedContext } from "../../context/RentCollectedContext";
import { formatNumber } from "../../helpers/NumberHelpers";

const RentCollectedDetails: FC = () => {
  const data = useContext(RentCollectedContext);

  const graphData = useMemo(() => {
    const rentCollectedEntries = Object.values(data.rentCollectedMonthly)
      .slice(0, -1)
      .map((item) => Object.values(item).at(-1) ?? 0)
      .flat();
    const rentDueEntries = Object.values(data.rentDueMonthly).slice(0, -1);

    const entries = Object.keys(data.rentDueMonthly)
      .slice(1)
      .map((key, index) => {
        const keyComponents = key.split("M");

        return {
          rentDueValue: rentDueEntries[index],
          rentCollectedValue: (
            (rentCollectedEntries[index] / rentDueEntries[index]) *
            100
          ).toFixed(2),
          rentDueLabel: formatDate(
            new Date(
              parseInt(keyComponents[0].padStart(4, "20")),
              parseInt(keyComponents[1]) - 1,
              1
            ),
            { day: false }
          ),
          rentCollectedLabel: formatDate(
            new Date(
              parseInt(keyComponents[0].padStart(4, "20")),
              parseInt(keyComponents[1]) - 1,
              1
            ),
            { day: false }
          ),
          rentDueUnit: "CHF",
          rentCollectedUnit: "%",
        };
      });

    const rentDueDomain = [
      Math.min(...rentDueEntries) - 10000,
      Math.max(...rentDueEntries) + 2000,
    ];

    const rentCollectedDomain = [
      Math.ceil(
        Math.min(
          ...entries.map((entry) => parseFloat(entry.rentCollectedValue))
        ) / 10
      ) *
        10 -
        20,
      100,
    ];

    return {
      rentDueGraph: {
        data: entries.map((item) => ({
          value: item.rentDueValue,
          label: item.rentDueLabel,
        })),
        domain: rentDueDomain,
      },

      rentCollectedGraph: {
        data: entries.map((item) => ({
          value: item.rentCollectedValue,
          label: item.rentCollectedLabel,
        })),
        domain: rentCollectedDomain,
      },
    };
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <PageTitle title="Rent Collected" />
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="subtitle2" fontWeight="bold">
          État Locatif (CHF)
        </Typography>
        <AreaChart
          values={graphData.rentDueGraph.data}
          domain={graphData.rentDueGraph.domain as [number, number]}
          width="100%"
          height="30vh"
          tooltipTitle="État Locatif"
          unit="CHF"
          props={{
            syncId: "x",
          }}
          tooltipValueFormatter={(value) =>
            formatNumber({ value, options: { round: true, format: ", " } })
          }
        />
        <Typography variant="subtitle2" fontWeight="bold">
          Rent Collected (%)
        </Typography>
        <AreaChart
          values={graphData.rentCollectedGraph.data}
          domain={graphData.rentCollectedGraph.domain as [number, number]}
          width="100%"
          height="30vh"
          tooltipTitle="Rent Collected"
          unit="%"
          showAxisUnit={true}
          props={{
            syncId: "x",
          }}
        />
      </Box>
    </Box>
  );
};

export default RentCollectedDetails;
