import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Box, styled, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../store";
import { selectMode } from "../../store/ui.slice";

interface MetricCardSimpleProps {
  title: string;
  value: number | string;
  caption: string;
  unit?: string;
  variant?: string;
  icon?: IconDefinition;
  disableHover?: boolean;
  onClick?: () => void;
}

const MetricCardSimple: React.FC<MetricCardSimpleProps> = ({
  title,
  value,
  caption,
  unit,
  variant = "simple",
  icon,
  disableHover = true,
  onClick,
}) => {
  const theme = useTheme();

  const mode = useAppSelector(selectMode);

  if (variant === "simple") {
    return (
      <MetricCardRoot mode={mode} disableHover={disableHover} onClick={onClick}>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="subtitle2">{title}</Typography>
          {icon && (
            <FontAwesomeIcon icon={icon} color={theme.palette.text.primary} />
          )}
        </Box>
        <Box display="flex" alignItems="top" gap={0.5}>
          <Typography variant="h5">{value}</Typography>
          <Typography variant="caption" lineHeight="24px">
            {unit}
          </Typography>
        </Box>
        <Typography variant="body2">{caption}</Typography>
      </MetricCardRoot>
    );
  } else if (variant === "inverse") {
    return (
      <MetricCardRoot
        inverse={true}
        mode={mode}
        disableHover={disableHover}
        onClick={onClick}
      >
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="subtitle2">{title}</Typography>
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              color={mode === "light" ? "white" : theme.palette.text.primary}
            />
          )}
        </Box>
        <Box display="flex" alignItems="top" gap={0.5}>
          <Typography variant="h5">{value}</Typography>
          <Typography variant="caption" lineHeight="24px">
            {unit}
          </Typography>
        </Box>
        <Typography variant="body2">{caption}</Typography>
      </MetricCardRoot>
    );
  } else {
    return <div></div>;
  }
};

const MetricCardRoot = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "inverse" && prop !== "mode" && prop !== "disableHover",
})<{ inverse?: boolean; mode: "light" | "dark"; disableHover?: boolean }>(
  ({ theme, inverse, disableHover, mode }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  padding: 16px;
  box-sizing: border-box;
  border-radius: 12px;
  background: ${inverse ? theme.palette.secondary.main : theme.cardBackground};
  box-shadow: 3px 3px 6px 1px rgba(16, 24, 40, 0.05);

  & .MuiTypography-root {
    color: ${mode === "light" && inverse ? "white" : "auto"};
  }

  ${
    disableHover
      ? `&:hover { 
        box-shadow: 0px 0px 0px 2px rgba(128, 141, 254, 0.25);
      }`
      : `
      &:hover {
        filter: brightness(1.1);
        box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.05),
          0px 0px 0px 2px rgba(128, 141, 254, 0.25);
        cursor: pointer;
        transform: scale(1.01);
      }
      `
  }
`
);

export default MetricCardSimple;
