import { styled } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTheme } from "@mui/material/styles";

interface Particle {
  x: number;
  y: number;
  xs: number;
  ys: number;
  l: number;
  color: string;
}

export type HyperspaceHandle = {
  jump: () => void;
};

const Hyperspace = forwardRef<HyperspaceHandle>((_, ref) => {
  const theme = useTheme();
  const width = window.innerWidth;
  const height = window.innerHeight;
  const initialSpeed = 0.008;
  const initialSpawnRadius = 1200;
  const spawnRadius = 300;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
  const [speed, setSpeed] = useState(initialSpeed);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    if (canvasRef.current) {
      const _ctx = canvasRef.current.getContext("2d");
      if (_ctx) {
        _ctx.lineWidth = 2;
        _ctx.lineCap = "round";
        setCtx(_ctx);
      }
    }
  }, []);

  const particles = useMemo(() => {
    const initialParticles: Particle[] = [];
    const maxParts = 200;
    for (let a = 0; a < maxParts; a++) {
      const initX =
        width / 2 + Math.random() * initialSpawnRadius - initialSpawnRadius / 2;
      const initY =
        height / 2 +
        Math.random() * initialSpawnRadius -
        initialSpawnRadius / 2;

      const color = theme.hyperspaceColor ?? theme.palette.primary.main;

      initialParticles.push({
        x: initX,
        y: initY,
        xs: (initX - width / 2) * initialSpeed,
        ys: (initY - height / 2) * initialSpeed,
        l: Math.floor(Math.random() * (10 - 2 + 1) + 2),
        color,
      });
    }
    return initialParticles;
  }, [height, theme.hyperspaceColor, theme.palette.primary.main, width]);

  const move = () => {
    for (let b = 0; b < particles.length; b++) {
      const p = particles[b];
      p.x += p.xs;
      p.y += p.ys;

      if (p.x < 0 || p.y < 0 || p.x > width || p.y > height) {
        p.x = width / 2 + Math.random() * spawnRadius - spawnRadius / 2;
        p.y = height / 2 + Math.random() * spawnRadius - spawnRadius / 2;
        p.xs = (p.x - width / 2) * speed;
        p.ys = (p.y - height / 2) * speed;
      }
    }
  };

  const draw = () => {
    if (!ctx) {
      return;
    }
    ctx.clearRect(0, 0, width, height);
    for (let c = 0; c < particles.length; c++) {
      const p = particles[c];
      ctx.strokeStyle = p.color;
      ctx.beginPath();
      ctx.moveTo(p.x, p.y);
      ctx.lineTo(p.x + p.l * p.xs, p.y + p.l * p.ys);
      ctx.stroke();
    }
    move();
  };

  setInterval(draw, 30);

  const jump = () => {
    let factor = 0.001;
    setTimeout(() => setFade(true), 2000);
    const interval = setInterval(() => {
      setSpeed((prevSpeed) => prevSpeed + factor);
      factor += 0.001;
      if (speed > 100) {
        clearInterval(interval);
      }
    }, 200);
  };

  useImperativeHandle(ref, () => ({
    jump,
  }));

  return <Canvas ref={canvasRef} width={width} height={height} fade={fade} />;
});

const Canvas = styled("canvas", {
  shouldForwardProp: (prop) => prop !== "fade",
})<{ fade: boolean }>`
  opacity: ${(props) => (props.fade ? 0 : 1)};
  transition: opacity 1s;
`;

export default Hyperspace;
