import { Box, styled, Typography } from "@mui/material";
import { FC } from "react";

type WelcomeProps = {
  fade?: boolean;
  onClick: () => void;
};

const Welcome: FC<WelcomeProps> = ({ fade = true, onClick }) => {
  return (
    <WelcomeTextContainer
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
      fade={fade}
      onClick={onClick}
    >
      <Typography variant="h3" align="center">
        Welcome to Asset Manager OS
      </Typography>
      <Typography variant="body1" align="center">
        Click to continue
      </Typography>
    </WelcomeTextContainer>
  );
};

const WelcomeTextContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "fade",
})<{ fade: boolean }>`
  opacity: ${(props) => (props.fade ? 0 : 1)};
  cursor: pointer;
  transition: opacity 2s ease-in-out;
`;

export default Welcome;
