import { useState, useEffect, createContext, ReactNode } from "react";
import { auth, db } from "../firebaseConfig";
import { User, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";

type Auth = {
  isAuthenticated: boolean | null;
  user: User | undefined;
  userAcceptedDisclaimer: boolean;
};

const initialValue: Auth = {
  isAuthenticated: null,
  user: undefined,
  userAcceptedDisclaimer: false,
};

export const AuthContext = createContext<Auth>(initialValue);

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [user, setUser] = useState<User>();
  const [userAcceptedDisclaimer, setUserAcceptedDisclaimer] =
    useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setUser(user as User);

      const handleUserConsent = async (user: User | null) => {
        if (user) {
          const userRef = doc(db, `investis-amp-user-consent/${user.uid}`);
          const docSnap = await getDoc(userRef);
          if (!docSnap.exists) {
            setDoc(userRef, {
              acceptedConsumptionDisclaimer: false,
            });
          } else {
            setUserAcceptedDisclaimer(
              docSnap.data()?.acceptedConsumptionDisclaimer
            );
          }
        }
      };

      if (user) {
        handleUserConsent(user);
      }
    });

    return () => unsubscribe();
  }, [userAcceptedDisclaimer]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, userAcceptedDisclaimer }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
